/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

const CloseIcon = ({ color = "#FFFFFF" }) => (
  <svg className="CloseIcon" x="0px" y="0px" viewBox="0 0 79.2 79.2" sx={{ stroke: color,
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
         stroke: "#00297A",
      },
   }}>
    <line x1="23.3" y1="55.9" x2="55.9" y2="23.3" />
    <line x1="23.3" y1="23.3" x2="55.9" y2="55.9" />
  </svg>
)

CloseIcon.propTypes = {
}

export default CloseIcon
