import React from "react"

const resolve = entity => {
  return <JSONComponent component={entity} />
}

const componentResolver = data => {
  const components = []

  if (!data) {
    return []
  }

  if (!Array.isArray(data)) {
    components.push(resolve(data.entity))
  } else {
    data.forEach(({ entity }) => {
      components.push(resolve(entity))
    })
  }

  return components
}

export default componentResolver
