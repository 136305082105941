/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { CustomLink } from "components";

const CVEListing = ({advisories = []}) => {
  return (
    <ul sx={{
      paddingLeft: "1rem",
      a: {
        borderBottom: "none",
        fontFamily: "body",
      },
      li: {
        mb: "spacing1",
      }
    }}>
      {
        advisories &&
        advisories.map((item, i) => {
          const path = item.url.toLowerCase()
          return (<li key={i}><CustomLink href={ path }>{ item.title }</CustomLink></li>)
        })
      }
    </ul>
  )
}

CVEListing.propTypes = {
}

export default CVEListing
