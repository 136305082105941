/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { ModalTrigger, RegistrationModal } from "components"
import { componentResolver, oktaEnvCheck } from "helpers"

const RegistrationButton = ({ title, customContent, triggerElement, buttonSize, buttonStyle, linkStyle, classes }) => {
  const [isRegisterPage, setIsRegisterPage] = useState(false)

  useEffect(() => {
    if (window.location.pathname === "/register") {
      setIsRegisterPage(true)
    }
  }, [])

  if (oktaEnvCheck("GATSBY_HEADER_SHOW_REGISTER_BUTTON") === "true") {
    const components = componentResolver(customContent)
    return (
      <ModalTrigger
        triggerElement={triggerElement}
        buttonSize={buttonSize}
        buttonStyle={buttonStyle}
        linkStyle={linkStyle}
        classes={classes}
        triggerStylesOverride={{
          display: isRegisterPage ? "none !important" : "visible"
        }}
        modalContent={
           <RegistrationModal
            customContent={
              <div>
                {components &&
                components.map((item) => {
                  return (
                    <React.Fragment key={item.props.component.entityUuid}>
                      {item}
                    </React.Fragment>
                  )
                })}
              </div>
            }
           />
        }
      >
        {title}
      </ModalTrigger>
    )
  }

  return null
}

RegistrationButton.propTypes = {
}

export default RegistrationButton
