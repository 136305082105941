/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

const DropDownIcon = ({ color = "white", isOpen = false }) => (
  <svg className="DropDownIcon" width="11" height="6" viewBox="0 0 11 6" fill="none" sx={{
    transform: isOpen ? "rotate(180deg)" : null,
  }}>
    <path sx={{
      stroke: color,
      strokeWidth: "2px",
    }} d="M1.00002 0.499998L5.3302 5L9.66028 0.499999" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default DropDownIcon
