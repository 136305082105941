/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";

const PlusIcon = ({ color, isOpen }) => (
  <svg className="PlusIcon" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <line y1="9" x2="20" y2="9" sx={{ stroke: color || "primary", strokeWidth: "3" }}  />
    <path d="M10 3.80104e-08L10 20" sx={{ stroke: color || "primary", strokeWidth: "3", opacity: isOpen ? 0 : 1 }} />
  </svg>
)

PlusIcon.propTypes = {
}

export default PlusIcon
