/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

const HeadingTag = ({ align, classes = "", display, text, tag, overrideStyles }) => {
  const Heading = tag ? `${tag}` : "h1"
  const DisplaySize = display && display === "default" ? null : display

  if (text) {
    return <Heading
      className={`HeadingTag ${classes}`}
      sx={{
        variant: DisplaySize === null ? null : `text.${DisplaySize}`,
        textAlign: align ? align : null,
        ...overrideStyles
      }}>
      {text}
    </Heading>
  }

  return null
}

HeadingTag.propTypes = {
  classes: PropTypes.string,
  overrideStyles: PropTypes.object,
  data: PropTypes.object,
}

export default HeadingTag
