/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
// import { Link } from "@reach/router";
import { Link } from "gatsby"
import { internalURL } from "helpers"

const CustomLink = ({ children, classes = "", external, href = "", onClickFunction, style = "primary", overrideStyles }) => {
  if (href == null) {
    return null
  }

  if (external || href.includes("http")) {
    return (
      <a
        className={`CustomLink ${classes}`}
        sx={{
          variant: `links.${style}`,
          ...overrideStyles
        }}
        href={href}>{children}
      </a>
    )
  }

  if (onClickFunction) {
    return (
      <span onClick={onClickFunction}>
      <Link
        className={`CustomLink ${classes}`}
        sx={{
          variant: `links.${style}`,
          ...overrideStyles
        }}
        to={internalURL(href)}>{children}
      </Link>
      </span>
    )
  }
  return (
    <Link
      className={`CustomLink ${classes}`}
      sx={{
        variant: `links.${style}`,
        ...overrideStyles
      }}
      to={internalURL(href)}>{children}
    </Link>
  )
}

CustomLink.propTypes = {
}

export default CustomLink
