/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
// import { keyframes } from '@emotion/react'
import { CloseIcon, Portal } from "components"

const Modal = ({ children, open, close, render, style, overrideStyles }) => {
  const modalWrapperStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: "modalZIndex",
  }

  const modalInnerWrapperStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  }

  const modalContentStyles = {
    position: "relative",
    background: "white",
    borderRadius: [0, null, null, "2px"],
    p: "spacing3",
    pb: ["spacing5", null, null, "spacing3"],
    width: "100%",
    maxWidth: ["100%", null, null, "600px", "800px", "900px"],
    minHeight: ["100%", null, null, "0", null],
    maxHeight: ["100%", null, null, null, "90vh"],
    height: "auto",
    zIndex: 10,
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.3)",
    overflow: "auto",
    animationName: "wave",
    animationDuration: "0.35s",
    animationFillMode: "forwards",
    ...style
  }

  const closeStyles = {
    cursor: "pointer",
    display: "block",
    height: "50px",
    width: "50px",
    mr: 0,
    ml: "auto",

    "svg": {
      display: "inline-block"
    }
  }

  const overlayStyles = {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: "black",
    opacity: 0.5
  }

  return (
    <Portal elementName="modal">
      <div sx={{ ...modalWrapperStyles, ...overrideStyles }}>
        <div sx={{ ...modalInnerWrapperStyles }}>
          <div sx={{ ...modalContentStyles }}>
            <div onClick={close} role="button" tabIndex="0" aria-label="Close modal" sx={{ ...closeStyles }}>
              <CloseIcon color="primary" />
            </div>
            {children}
          </div>
        </div>
        <div sx={{ ...overlayStyles }} onClick={close} />
      </div>
    </Portal>
  )
}

Modal.propTypes = {
}

export default Modal
