export default {
  links:
  [
      {
          "label": "Okta.com",
          "description": null,
          "url": {
              "href": "https://okta.com",
              "internal": false
          }
      },
      {
          "label": "Privacy",
          "description": null,
          "url": {
              "href": "https://www.okta.com/privacy/",
              "internal": false
          }
      },
      {
          "label": "Security Advisories",
          "description": null,
          "url": {
              "href": "https://trust.okta.com/security-advisories/",
              "internal": false
          }
      },
      {
          "label": "Security Blog",
          "description": null,
          "url": {
              "href": "https://sec.okta.com/articles",
              "internal": false
          }
      }
  ],
  legal: `© ${new Date().getFullYear()} Okta, Inc. All rights reserved.`,
}