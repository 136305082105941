/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { Component } from "react"
import { Button, LogoIcon } from "components"
import { oktaEnvCheck } from "helpers"

class LoginModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ssoLogin: oktaEnvCheck("GATSBY_LOGIN_MODAL_SSO_LINK"),
      myOktaLogin: oktaEnvCheck("GATSBY_LOGIN_MODAL_MYOKTA_LINK"),
    }
  }

  render() {
    const { ssoLogin, myOktaLogin } = this.state;
    const { lang, customContent } = this.props;

    const choiceStyles = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: "1 1 50%",
      minWidth: "50%",
      padding: ["20px", null, null, "spacing3"],
      textAlign: ["center", null, null, "left"],
      color: "#000",

      "&:first-of-type": {
        textAlign: "center",
      }
    }

    return (
      <div sx={{
        width: "100%",
        marginTop: ["spacing4", null, null, "auto"],
      }} className="Login">
        <div sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mx: "auto",
          mb: "spacing5",
          "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
            justifyContent: "flex-start",
          },
        }}>
          <div sx={{
            flex: "0 1 300px",
            mx: "auto",
          }}>
            <LogoIcon overrideStyles={{
              m: "auto",
              maxWidth: ["200px", null, null, "300px"],
              padding: ["spacing1", null, null, 0],
            }} />
          </div>
        </div>

        <div sx={{
          display: "flex",
          flexDirection: ["column", null, null, "row"],
          justifyContent: "center",
          my: ["spacing2", null, null, "spacing4"],
          width: "100%",
        }} className="Login-content-wrapper">


          <div sx={{ ...choiceStyles }}>
            <p sx={{ color: "#000", minHeight: "60px" }}>I use Okta at my organization</p>
            <Button href={ssoLogin} style="primary">Continue with Okta</Button>
          </div>

          <div sx={{
            minWidth: "0",
            width: ["100%", null, null, "auto"],
            maxWidth: ["100%", null, null, "1px"],
            mx: ["0", null, null, "10px"],
            borderRight: ["none", null,  null, "1px solid rgba(0, 0, 0, 0.35)"],
            borderBottom: ["1px solid rgba(0, 0, 0, 0.35)", null, null,  "none"],
          }} className="Login-content-divider"></div>

          <div sx={{ ...choiceStyles }}>
            <p sx={{ color: "#000", minHeight: "60px", maxWidth: "280px", textAlign: "center"  }}>I only use Okta for events or other Okta resources</p>
            <Button href={myOktaLogin} style="secondary">Continue</Button>
          </div>
        </div>


        <small sx={{
          display: "block",
          textAlign: "center",
          margin: "auto",
        }}>Registration powered by Okta</small>
        {customContent}
      </div>
    )
  }
}

LoginModal.propTypes = {
}

export default LoginModal
