/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

const Decoration1 =  require("../../media/images/decoration1.png");
const Decoration2 =  require("../../media/images/decoration2.png");
const Decoration3 =  require("../../media/images/decoration3.png");
const Decoration4 =  require("../../media/images/decoration4.png");
const Decoration5 =  require("../../media/images/decoration5.png");

const SectionDecorator = ({ position, type }) => {
  const getPosition = (value) => {
    if (value === "topLeft") {
      return (
        {
          top: 0,
          left: 0
        }
      )
    }
    if (value === "top") {
      return (
        {
          top: 0,
          left: "50%",
          transform: "translateX(-50%)"
        }
      )
    }
    if (value === "topRight") {
      return (
        {
          top: 0,
          right: 0
        }
      )
    }
    if (value === "bottomLeft") {
      return (
        {
          bottom: 0,
          left: 0
        }
      )
    }
    if (value === "bottom") {
      return (
        {
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)"
        }
      )
    }
    if (value === "bottomRight") {
      return (
        {
          bottom: 0,
          right: 0
        }
      )
    }
    if (value === "right") {
      return (
        {
          right: 0,
          top: "50%",
          transform: "translateY(-50%)"
        }
      )
    }

    return null
  }

  const getDecoration = (value) => {
    if (value === "decoration1") {
      const item = {
        img: Decoration1,
        styles: {
          height: ["510px", null, null, null, null,"540px"],
          width: ["345px", null, null, null, null,"405px"],
          // minWidth: "405px",
          // position: "relative",
          // right: ["-15%", null, null, "-5%", "-10%", null, 0]
        }
      }
      return item
    }
    if (value === "decoration2") {
      const item = {
        img: Decoration2,
        styles: {
          height: "7vw",
          width: "7vw",
        }
      }
      return item
    }
    if (value === "decoration3") {
      const item = {
        img: Decoration3,
        styles: {
          height: "13.45vw",
          width: "26.765vw",
        }
      }
      return item
    }
    if (value === "decoration4") {
      const item = {
        img: Decoration4,
        styles: {
          height: "6.576vw",
          width: "26.765vw",
        }
      }
      return item
    }
    if (value === "decoration5") {
      const item = {
        img: Decoration5,
        styles: {
          height: "21vw",
          width: "26.5vw",
        }
      }
      return item
    }
    return null
  }

  const styles = getPosition(position)
  const decoration = getDecoration(type)

  return (
    <div sx={{
      position: "absolute",
      maxHeight: "100%",
      overflow: "hidden",
      zIndex: "-1",
      ...styles
    }}>
      {styles && decoration && <img sx={{ display: ["none", null, null, "block"], ...decoration.styles }} src={decoration.img} alt="background graphic" />}
    </div>
  )
}

SectionDecorator.propTypes = {
}

export default SectionDecorator
