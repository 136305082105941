const internalURL = url => {
    if (url && url.slug) {
        url = url.slug
    }

    if (url) {

        url = url
          .replace(`${process.env.GATSBY_PATH_PREFIX}/homepage`, "/")
          .replace("internal:", "")
          .replace("base:", "")
          .replace(process.env.GATSBY_PATH_PREFIX, "")
    }
    return url;
}

module.exports = internalURL
