const FormElementStyles = {
  FileStyles: {
    width: "100%",
    height: "spacing4",
    lineHeight: "1.5",
    fontSize: "body",
    fontFamily: "body",
    cursor: "pointer",
    fontWeight: "body",
    "&:focus": {
      borderColor: "inputFocus",
      borderWidth: "inputFocus",
    },
  },

  FieldWrapperStyles: {
    position: "relative",
    marginBottom: "spacing2",
  },

  InputStyles: {
    width: "100%",
    borderRadius: "0",
    borderColor: "gray400",
    borderStyle: "solid",
    borderWidth: "1px",
    height: "spacing4",
    lineHeight: "1.5 !important",
    p: "6px 12px",
    backgroundColor: "background",
    variant: "text.body",
    "&:focus": {
      borderColor: "inputFocus",
      outline: "2px solid",
      outlineColor: "inputFocus",
    },
  },

  LabelStyles: {
    display: "flex",
    alignItems: "center",
    variant: "text.body",
    lineHeight: "1.5 !important",
    mb: "spacing1",
  },

  SelectStyles: {
    width: "100%",
    borderColor: "gray400",
    borderStyle: "solid",
    borderWidth: "1px",
    height: "spacing4",
    lineHeight: "1.5 !important",
    variant: "text.body",
    p: "6px 12px",
    WebkitAppearance: "none",
    backgroundColor: "background",
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='9' height='5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.4 0h8.2L4.5 5' fill='%235c6971'/%3E%3C/svg%3E")`,
    backgroundPosition: "calc(100% - 12px) center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    "&:focus": {
      borderColor: "inputFocus",
      borderWidth: "inputFocus",
    },
  },

  TextAreaStyles: {
    width: "100%",
    borderRadius: "0",
    borderColor: "gray400",
    borderStyle: "solid",
    borderWidth: "1px",
    height: "136px",
    lineHeight: "1.5 !important",
    p: "6px 12px",
    mb: "10px",
    backgroundColor: "background",
    variant: "text.body",

    "&:focus": {
      borderColor: "inputFocus",
    },
  },
}

module.exports = FormElementStyles
