/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Modal } from "components"
import { ModalProvider } from "providers"

const ModalTrigger = ({ buttonStyle, buttonSize, children, classes, linkStyle = "primary", modalContent, modalStyle, triggerStylesOverride, triggerElement, overrideStyles }) => {
  const { open, openModal, closeModal } = ModalProvider();
  const Element = triggerElement ? `${triggerElement}` : "button"

  const handleKeyPressOpen = (e) => {
    if ((e.keyCode === 32 || e.keyCode === 13) && !open) {
      openModal()
    }
  }

  useEffect(() => {
    const handleKeyPressClose = (e) => {
      if (e.key === "Escape" && open) {
        closeModal();
      }
    }

    window.addEventListener("keydown", handleKeyPressClose);

    return () => {
      window.removeEventListener("keydown", handleKeyPressClose);
    }
  })

  if (Element === "button") {
    return (
      <React.Fragment>
        <Button classes={classes} style={buttonStyle} size={buttonSize} aria-label="Open modal" clickFunction={openModal} overrideStyles={triggerStylesOverride}>{children}</Button>
        { open && <Modal open={open} close={closeModal} style={modalStyle} overrideStyles={overrideStyles}>{modalContent}</Modal> }
      </React.Fragment>
    )
  }

  if (Element === "a" || Element === "link") {
    return (
      <React.Fragment>
        <a
          href="#"
          aria-label="Open modal"
          onClick={openModal}
          onKeyDown={handleKeyPressOpen}
          classes={classes}
          sx={{
            variant: `links.${linkStyle}`,
            ...triggerStylesOverride
          }}>
          {children}
        </a>
        { open && <Modal open={open} close={closeModal} style={modalStyle} overrideStyles={overrideStyles}>{modalContent}</Modal> }
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Element
        sx={{
          cursor: "pointer",
          ...triggerStylesOverride
        }}
        aria-label="Open modal"
        classes={classes}
        role="button"
        tabIndex="0"
        onClick={openModal}
        onKeyDown={handleKeyPressOpen}>
        {children}
      </Element>
      { open && <Modal open={open} close={closeModal} style={modalStyle} overrideStyles={overrideStyles}>{modalContent}</Modal> }
    </React.Fragment>
  )
}

ModalTrigger.propTypes = {
}

export default ModalTrigger
