/* eslint no-unused-vars: 0 */
// import theme from "@bit/oktacorp.oktaweb-ui.theme"
import { cssReset } from "helpers"
// console.log("theme: ", theme)

// TBD: Move these into a tokens file?
const black = "#000"
const white = "#FFF"
const primary = "#00297A"
const primaryLight = "#1662DD"
const secondary = "#EBEBED"
const secondaryLight = "#EBEBED"
const tertiary = "#4cbf9d"
const tertiaryLight = "#4cbf9d"
const tertiaryMedium = "#4cbf9d"

const darkSectionButtonOverrides = {
  "&[class*='Button-primary-regular']": {
    variant: "buttons.primaryLight",
  },
  "&[class*='Button-primary-large']": {
    variant: "buttons.primaryLightLarge",
  },
  "&[class*='Button-secondary-regular']": {
    variant: "buttons.secondaryLight",
  },
  "&[class*='Button-secondary-large']": {
    variant: "buttons.secondaryLightLarge",
  },
}

const globalStyles = {
  breakpoints: ["320px", "768px", "1024px", "1200px", "1400px", "1700px"],
  buttons: {
    primary: {
      display: "inline-block",
      textDecoration: "none !important",
      textAlign: "center",
      fontWeight: "normal",
      border: "2px solid",
      borderRadius: "0px",
      py: "spacing1",
      px: "spacing3",
      marginBottom: "spacing1",
      transition:
        "color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease",
      variant: "text.bodyTitle",
      cursor: "pointer",

      "& + .Button, & + .CustomLink": {
        marginLeft: "spacing2",
      },

      borderColor: "buttonPrimaryColor",
      color: "white",
      bg: "buttonPrimaryColor",

      "&:hover": {
        bg: "transparent",
        color: "buttonPrimaryColor",
        textDecoration: "none",
        border: "2px solid",
        borderColor: "buttonPrimaryColor",
      },

      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        borderColor: "#00297A",
        color: "#fff",
        bg: "#00297A",
        "&:hover": {
          color: "#00297A",
          borderColor: "#00297A",
        },
      },
    },

    primaryLight: {
      variant: "buttons.primary",
      border: "2px solid",
      borderColor: "secondary",
      borderRadius: "0",
      color: "primary",
      bg: "secondary",

      "&:hover": {
        bg: "transparent",
        color: "secondary",
        textDecoration: "none",
        borderColor: "secondary",
      },
    },

    primaryLarge: {
      variant: "buttons.primary",
      fontFamily: "heading",
      borderRadius: "0",
      fontSize: "1.25rem",
      lineHeight: "130%",
      py: "spacing2",
      px: "spacing4",
      border: "3px solid",

      "&:hover": {
        border: "3px solid",
      },
    },

    primaryLightLarge: {
      variant: "buttons.primaryLight",
      fontFamily: "heading",
      fontSize: "1.25rem",
      lineHeight: "130%",
      border: "2px solid",
      py: "spacing2",
      px: "spacing4",
      border: "3px solid",
    },

    secondary: {
      variant: "buttons.primary",
      borderColor: "primary",
      color: "primary",
      bg: "transparent",

      "&:hover": {
        bg: "primary",
        color: "white",
        textDecoration: "none",
        border: "2px solid",
        borderColor: "primary",
      },

      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        borderColor: "#00297A",
        color: "#00297A",

        "&:hover": {
          bg: "#00297A",
          color: "#fff",
          borderColor: "#00297A",
        },
      },
    },

    secondaryLight: {
      variant: "buttons.secondary",
      borderColor: "secondary",
      color: "secondary",

      "&:hover": {
        bg: "secondary",
        color: "primary",
        textDecoration: "none",
        borderColor: "secondary",
      },
    },

    secondaryLarge: {
      variant: "buttons.secondary",
      fontFamily: "heading",
      fontSize: "1.25rem",
      lineHeight: "130%",
      py: "spacing2",
      px: "spacing4",
      border: "3px solid",

      "&:hover": {
        border: "3px solid",
        borderColor: "primary",
      },
    },

    secondaryLightLarge: {
      variant: "buttons.secondaryLight",
      fontFamily: "heading",
      fontSize: "1.25rem",
      lineHeight: "130%",
      py: "spacing2",
      px: "spacing4",
      border: "3px solid",
    },
  },
  colors: {
    background: white,
    black: black,
    white: white,
    primary: primary,
    primaryLight: primaryLight,
    secondary: secondary,
    secondaryLight: secondaryLight,
    tertiary: tertiary,
    tertiaryLight: tertiaryLight,
    tertiaryMedium: tertiaryMedium,

    inputFocus: "#92BCFF",
    inputError: "#DA372C",
    buttonPrimaryColor: primary,
    linkPrimaryColor: primary,
    linkPrimaryHoverColor: "#1662DD",
    linkSecondaryColor: white,
    linkSecondaryHoverColor: "#92BCFF",
    speakerGroupTitleColor: tertiary,
    speakerBorderHoverColor: tertiaryMedium,

    gray000: "#F5F5F6",
    gray100: "#EBEBED",
    gray200: "#D7D7DC",
    gray300: "#C1C1C8",
    gray400: "#AAAAB4",
    gray500: "#8C8C96",
    gray600: "#6E6E78",
    gray700: "#585862",
    gray800: "#41414B",
    gray900: "#212126",
    black: "#000",
    turquois100: "#ABD5D6",
  },
  fonts: {
    body: "'ABCWhyte Book', 'Helvetica Neue', sans-serif",
    heading: "'ABCWhyte Medium', 'Helvetica Neue', sans-serif",
    monospace: "Menlo, monospace",
  },
  forms: {
    marketo: {
      display: "block",
      maxWidth: "100%",
      width: "800px",
      margin: "auto",

      form: {
        width: "100% !important",

        label: {
          "&.mktoLabel": {
            variant: "text.body",
            fontWeight: "normal !important",
            width: "100% !important",
            mb: "spacing1",

            "&[for='hasPromotionCode'], &[for='promotionCode'], &[for='Communication_Opt_in__c']":
              {
                display: "inline-block",
                verticalAlign: "middle",
                float: "none",
                width: "100% !important",
                margin: "10px 0 5px",
                fontSize: "1rem",
              },
          },
        },

        "&.mktoForm": {
          ".mktoHtmlText": {
            width: "100% !important",
            paddingTop: "10px",
            variant: "text.caption",

            a: {
              margin: ["5px 0", null, null, null, "20px 0"],
              padding: [0, null, null, null, "inherit"],
            },

            p: {
              variant: "text.caption",
            },
          },

          ".mktoError": {
            position: "relative !important",
            background: "#dd0744",
            right: "0 !important",
            bottom: "0 !important",
            borderRadius: "2px 2px 0 0",
            order: "1",

            ".mktoErrorArrowWrap": {
              display: "none",
            },

            ".mktoErrorMsg": {
              marginTop: "0",
              textShadow: "none",
              boxShadow: "none",
              border: "none",
              backgroundImage: "none",
              backgroundColor: "#dd0744",
              fontSize: "14px !important",
            },
          },

          ".mktoField.mktoInvalid": {
            order: "2",
          },

          ".mktoRequiredField": {
            ".mktoAsterix": {
              float: "left",
              pl: 0,
              pr: "5px",
            },
          },

          ".mktoFieldDescriptor": {
            "&.mktoFormCol": {
              flex: "1 1 auto",
              float: "none !important",
              marginBottom: "16px !important",
            },
          },

          ".mktoRadioList": {
            width: "100% !important",
            float: "none",
            display: "inline-block",
            verticalAlign: "middle",
          },

          ".mktoLogicalField": {
            width: "100% !important",
          },

          textarea: {
            color: "primary",
            border: "none",
            borderRadius: "0px",
            padding: ["18px 10px", null, null, null, "10px"],
            fontSize: "18px",
            width: "100% !important",
          },

          input: {
            color: "primary",
            border: "none",
            borderRadius: "0px",
            padding: ["18px 10px", null, null, null, "10px"],
            fontSize: "18px",
            width: "100% !important",

            "&[type='radio']": {
              py: ["0", null, null, null, "10px"],
              pl: ["0", null, null, null, "10px"],
            },
          },

          ".mktoCheckboxList": {
            width: "auto !important",

            "input[type='checkbox']": {
              width: "auto !important",
            },
          },

          "&.mktoHasWidth.mktoLayoutLeft": {
            ".mktoButtonRow": {
              "span.mktoButtonWrap.mktoSimple": {
                marginLeft: "auto !important",

                "button.mktoButton": {
                  backgroundImage: "none",
                  variant: "buttons.primary",
                },
              },
            },

            ".mktoFormRow": {
              display: "flex",

              ".mktoOffset, .mktoGutter.mktoHasWidth, .mktoClear": {
                display: "none",
              },

              ".mktoFieldWrap": {
                display: "flex",
                flexDirection: "column",
                position: "relative",
                width: "100%",

                "&.d-flex-row": {
                  flexDirection: "row",
                },

                "&.row-flex": {
                  flexDirection: "row",

                  ".mktoCheckboxList": {
                    order: "-1",

                    input: {
                      mt: "5px",
                    },
                  },
                },

                "[for='Communication_Opt_in__c']": {
                  order: "2",
                  flex: "1 1 auto",
                  maxWidth: "350px",
                  marginTop: "0 !important",
                },
              },

              select: {
                display: "block",
                fontSize: "16px",
                color: "primary",
                lineHeight: "1.3",
                padding: ".6em 1.4em .5em .8em",
                height: "50px",
                width: "100% !important",
                maxWidth: "100%",
                boxSizing: "border-box",
                margin: "0",
                borderRadius: "0px",
                border: "none",
                MozAppearance: "none",
                WebkitAppearance: "none",
                appearance: "none",
                backgroundColor: "#fff",
                backgroundImage: `url("data:image/svg+xml",charset=UTF-8,%3csvg xmlns='http: "//www.w3.org/2000/svg' width='292.4' height='292.4'%3e%3cpath fill='%23b6abde' d='M287 69.4a17.6 17.6 0 0 0-13-5.4H18.4c-5 0-9.3 1.8-12.9 5.4A17.6 17.6 0 0 0 0 82.2c0 5 1.8 9.3 5.4 12.9l128 127.9c3.6 3.6 7.8 5.4 12.8 5.4s9.2-1.8 12.8-5.4L287 95c3.5-3.5 5.4-7.8 5.4-12.8 0-5-1.9-9.2-5.5-12.8z'/%3e%3c/svg%3e")`,
                backgroundRepeat: "no-repeat, repeat",
                backgroundPosition: "right .7em top 50%, 0 0",
                backgroundSize: ".65em auto, 100%",

                "&:focus": {
                  outline: "none",
                },

                "&:-ms-expand": {
                  display: "none",
                },

                option: {
                  fontWeight: "normal",
                },
              },
            },
          },
        },

        ".Email-op-in": {
          flexDirection: "row",

          label: {
            margin: "0 0 0 24px",
          },

          ".mktoCheckboxList": {
            position: "absolute",
            left: "0",
            top: "57%",
            transform: "translate(0, -50%)",
            height: "auto",
            margin: "0",
          },
        },
      },
    },
  },
  gutters: {
    columns: {
      mx: ["-0.5rem", null, null, "-0.75rem", "-1rem", null, "-1.25rem"],
    },
    columnItem: {
      px: ["0.5rem", null, null, "0.75rem", "1rem", null, "1.25rem"],
      py: ["0.5rem", null, null, "0.75rem", "1rem", null, "1.25rem"],
    },
  },
  links: {
    primary: {
      color: "primary",
      textDecoration: "none",
      transition: "all 0.3s ease",
      fontFamily: "heading",
      borderBottom: "1.25px solid",
      borderBottomColor: "primary",

      "& + .CustomLink": {
        marginLeft: "spacing2",
      },

      "&:hover": {
        color: "linkPrimaryHoverColor",
      },

      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        color: "#00297A",

        "&:hover": {
          color: "#1662dd",
        },
      },
    },
    secondary: {
      variant: "links.primary",
      color: "linkSecondaryColor",
      borderBottom: "1px solid",
      borderColor: "#fff",
      fontFamily: "body",

      "&:hover": {
        color: "linkSecondaryHoverColor",
      },
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        color: "#fff",

        "&:hover": {
          color: "#92BCFF",
        },
      },
    },
    secondaryThin: {
      variant: "links.secondary",
      fontFamily: "body",
      fontSize: "0.85rem !important",
    },
    wrapper: {
      textDecoration: "none",
      borderBottom: "none !important",

      "&:hover": {
        ".Card-cta-link": {
          color: "linkSecondaryHoverColor",
          borderBottomColor: "linkSecondaryHoverColor",
        },
      },
    },
    mainNavLinks: {
      variant: "links.primary",
      borderBottomColor: "transparent",
      mb: "-2px",
    },
  },
  sections: {
    primary: {
      bg: "primary",
      color: "white",
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        bg: primary,
        color: "#fff",
      },

      "h1, h2, h3, h4, h5, h6, p, strong, li, ol": {
        color: "#fff",
        borderColor: "#fff",
      },
      a: {
        variant: "links.secondary",
        ...darkSectionButtonOverrides,
      },
      button: {
        variant: "links.secondary",
        ...darkSectionButtonOverrides,
      },
    },
    primaryLight: {
      variant: "sections.primary",
      bg: "primaryLight",
      color: "tertiaryMedium",
      p: {
        color: "tertiaryMedium",
      },

      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        bg: "#1662DD",
      },
    },
    primaryHero: {
      bg: "primary",
      color: "tertiaryMedium",

      "h1, h2, h3, h4, h5, h6, p, strong, li, ol": {
        color: "tertiaryMedium",
      },

      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        bg: primary,
        color: tertiaryMedium,

        "h1, h2, h3, h4, h5, h6, p, strong, li, ol": {
          color: tertiaryMedium,
        },
      },

      a: {
        variant: "links.secondary",
        ...darkSectionButtonOverrides,
      },
      button: {
        variant: "links.secondary",
        ...darkSectionButtonOverrides,
      },
    },
    secondary: {
      bg: "white",
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        bg: white,
      },
    },
    secondaryLight: {
      bg: "secondaryLight",
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        bg: secondaryLight,
      },
    },
    tertiary: {
      bg: "tertiary",
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        bg: tertiary,
      },
    },
    tertiaryLight: {
      bg: "tertiaryLight",
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        bg: tertiaryLight,
      },
    },
  },
  space: {
    spacing0: "0",
    spacing1: "0.5rem", // 8px
    spacing2: "1rem", // 16px
    spacing3: "1.5rem", // 24px
    spacing4: "2rem", // 32px
    spacing5: "3rem", // 48px
    spacing6: "4rem", // 64px
    spacing7: "6rem", // 96px
    spacing8: "8rem", // 128px
    spacing9: "12rem", // 192px
    spacing10: "16rem", // 256px
  },
  styles: {
    root: {
      ...cssReset,
      bg: "white",
      color: "primary",
      fontFamily: "body",
      width: "100%",
      overflowX: "hidden",
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        color: primary,
        backgroundColor: white,
      },
      h1: {
        variant: "text.title1",
      },
      h2: {
        variant: "text.title2",
      },
      h3: {
        variant: "text.title3",
      },
      h4: {
        variant: "text.title4",
      },
      h5: {
        variant: "text.title5Medium",
      },
      h6: {
        variant: "text.title6",
      },
      p: {
        variant: "text.body",
        "& + p": {
          mt: "spacing2",
        },
        a: {
          variant: "links.primary",
        },
      },
      strong: {
        fontWeight: "normal",
      },
      a: {
        color: "primary",
      },
      label: {
        variant: "text.body",
      },
      li: {
        variant: "text.body",
        color: "inherit",
      },
      img: {
        display: "block",
        margin: "auto",
        maxWidth: "100%",
      },
    },
  },
  text: {
    display1: {
      fontFamily: "body",
      fontSize: ["10rem", null, null, "12rem", "16rem"],
      fontWeight: "normal",
      lineHeight: "95%",
      letterSpacing: "-2%",
      color: "primary",
    },
    display2: {
      fontFamily: "body",
      fontSize: ["5rem", null, null, "6rem", "8rem"],
      fontWeight: "normal",
      lineHeight: "100%",
      letterSpacing: "-1%",
      color: "primary",
    },
    display3: {
      fontFamily: "body",
      fontSize: ["4rem", null, null, "5rem", "6rem"],
      fontWeight: "normal",
      lineHeight: "100%",
      letterSpacing: "-1%",
      color: "primary",
    },
    title1: {
      fontFamily: "body",
      fontSize: ["3rem", null, null, "3rem", "4rem"],
      fontWeight: "normal",
      lineHeight: "110%",
      color: "primary",
    },
    title1Medium: {
      variant: "text.title1",
      fontFamily: "heading",
    },
    title2: {
      fontFamily: "body",
      fontSize: ["2rem", null, null, "2.5rem", "3rem"],
      fontWeight: "normal",
      lineHeight: "120%",
      color: "primary",
    },
    title2Medium: {
      variant: "text.title2",
      fontFamily: "heading",
    },
    title3: {
      fontFamily: "body",
      fontSize: ["1.75rem", null, null, "1.85rem", "2rem"],
      fontWeight: "normal",
      lineHeight: "125%",
      color: "primary",
    },
    title3Medium: {
      variant: "text.title3",
      fontFamily: "heading",
    },
    title4: {
      fontFamily: "body",
      fontSize: ["1.3rem", null, null, "1.35rem", "1.5rem"],
      fontWeight: "normal",
      lineHeight: "135%",
      color: "primary",
    },
    title4Medium: {
      variant: "text.title4",
      fontFamily: "heading",
    },
    title5: {
      fontFamily: "body",
      fontSize: ["1.15rem", null, null, "1.2rem", "1.25rem"],
      fontWeight: "normal",
      lineHeight: "130%",
      color: "primary",
    },
    title5Medium: {
      variant: "text.title5",
      fontFamily: "heading",
    },
    title6: {
      fontFamily: "body",
      fontSize: ["1.025rem", null, null, "1.05rem", "1.15rem"],
      fontWeight: "normal",
      lineHeight: "140%",
      color: "primary",
    },
    title6Medium: {
      variant: "text.title6",
      fontFamily: "heading",
    },
    body: {
      fontFamily: "body",
      fontSize: "1rem",
      lineHeight: "140%",
      color: "primary",
    },
    bodyTitle: {
      variant: "text.body",
      fontFamily: "heading",
    },
    caption: {
      fontFamily: "body",
      fontSize: "0.875rem",
      lineHeight: "120%",
      color: "primary",
    },
    captionTitle: {
      variant: "text.caption",
      fontFamily: "heading",
    },
    categoryTag: {
      fontFamily: "heading",
      fontSize: "0.875rem",
      lineHeight: "110%",
      letterSpacing: "25%",
    },
  },
  zIndex: {
    modalZIndex: 9000,
  },
}

// console.log("theme: ", globalStyles)

export default globalStyles
