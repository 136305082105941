/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

const ColumnItem = ({ alignSelf, children, width, overrideStyles }) => {

  const generateFlexAmount = () => {
    if (width) {
      if (width === "100") {
        const l = `1 1 ${width}%`
        return {
          flex: l
        }
      } else {
        const defaultSize = "0 1 100%"
        const smallSize = "0 1 50%"
        const largeSize = `0 1 ${width}%`
        return {
          flex: [defaultSize, null, null, smallSize, largeSize]
        }
      }
    } else {
      return {
        flex: "1"
      }
    }
  };

  const generateWidth = () => {
    if (width) {
      if (width === "100") {
        return {
          width: [`${width}%`],
          maxWidth: [`${width}%`],
          minWidth: [`${width}%`],
        }
      } else {
        return {
          width: ["100%", null, null, "50%", `${width}%`],
          maxWidth: ["100%", null, null, "50%", `${width}%`],
          minWidth: ["100%", null, null, "50%", `${width}%`],
          "& [class*='ColumnItem']": {
            width: ["100%", "100%", null, null, "50%", `${width}%`],
            maxWidth: ["100%", "100%", null, null, "50%", `${width}%`],
            minWidth: ["100%", "100%", null, null, "50%", `${width}%`],
            pb: 0,
            pt: ["spacing2", "spacing2", null, null, 0],
            "&:first-of-type": {
              pt: 0,
              pb: ["spacing2", "spacing2", null, null, 0],
            }
          }
        }
      }
    } else {
      return null
    }
  };

  const calculatedFlex = generateFlexAmount();
  const calculatedWidth = generateWidth();

  return (
    <div className="ColumnItem" sx={{
      // display: "flex",
      // flexDirection: "column",
      alignSelf: alignSelf,
      mx: "0",
      variant: "gutters.columnItem",
      // pb: "spacing2",
      ...calculatedFlex,
      ...calculatedWidth,
      ...overrideStyles,
    }}>{children}</div>
  )
}

ColumnItem.propTypes = {
}

export default ColumnItem
