/** @jsx jsx */
import React, { Component } from "react"
import { jsx } from "theme-ui"
import Cookies from "js-cookie"
import axios from "axios";
import Helmet from "react-helmet"
import { Loading, WYSIWYG } from "components"

class FormMarketo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successfulSubmit: false,
      formIsLoaded: false,
    };

    const { formId } = this.props;
    this.formElement = `mktoForm_${formId}`;
  }

  componentDidMount() {
    this.checkMarketoScriptLoad();
  }

  checkMarketoScriptLoad() {
    if (!this.state.formIsLoaded) {
      this.handleMarketoLoading();
      this.changeEmailOptInCanada();
    }
  }

  changeEmailOptInCanada() {
    const self = this;

    if (typeof window.MktoForms2 !== 'undefined') {

      window.MktoForms2.whenReady(function(form) {
        const placeholder = document.querySelector(`#${self.formElement} .mktoPlaceholderEmail_Opt_In__c`);
        if (placeholder) {
          const placeholderParent = placeholder.parentElement;
          placeholderParent.classList.add('Email-op-in');
        }

        const agreeLabel = document.querySelector(
          `label[for='termsandConditions']`
        )
        if (agreeLabel) {
          const agreeLabelParent = agreeLabel.parentElement
          agreeLabelParent.classList.add("Email-op-in")
        }
      });

    }
  }

  handleMarketoLoading() {
    const { formId, cid, thankYouMessage, thankYouURL } = this.props;
    const cancelReload = () => {
      this.setState({
        successfulSubmit: true
      });
    }

    if (typeof window.MktoForms2 !== 'undefined') {
      if (formId) {
        // eslint-disable-next-line no-undef
        MktoForms2.loadForm("//pages.okta.com", "855-QAH-699", formId, function(form) {
          const refCookie = Cookies.get("_okta_cvent")

          if (form) {
            form.vals({
              cid: cid,
              "forum21europeRefID": refCookie
            });


            form.onSuccess(()=> {
              if (thankYouURL) {
                window.location = thankYouURL
                return
              }

              cancelReload();
              return false
            })
          }
        })

        this.setState({
          formIsLoaded: true
        });
      }
    } else {
      window.setTimeout(() => {
        this.checkMarketoScriptLoad();
      }, 500)
    }
  }

  render() {
    const { formId, thankYouMessage } = this.props;
    const { successfulSubmit } = this.state;
    return (
      <div>
        <Helmet>
          <script src="//pages.okta.com/js/forms2/js/forms2.min.js" async />
        </Helmet>

        <div sx={{ variant: "forms.marketo", }}>
          {!this.state.formIsLoaded && <Loading />}
          {!successfulSubmit && <form ref={this.formElement} id={`mktoForm_${formId}`} /> }
          {!successfulSubmit && <p><br/>By submitting, I agree to the processing and international transfer of my personal data by Okta as described in the <a href={`https://www.okta.com/privacy-policy/`} target={`blank`}>Privacy Policy</a>.</p>}
          {successfulSubmit && <div sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
              { thankYouMessage && <WYSIWYG content={thankYouMessage} /> }
            </div>
          }
        </div>
      </div>
    )
  }
}

export default FormMarketo
