const getSectionVariant = (color) => {
  if (color === "#00297A" || color === "primary") {
    return { variant: "sections.primary" }
  } else if (color === "primaryHero") {
    return { variant: "sections.primaryHero" }
  } else if (color === "primaryLight") {
    return { variant: "sections.primaryLight" }
  } else if (color === "secondary") {
    return { variant: "sections.secondary" }
  } else if (color === "secondaryLight") {
    return { variant: "sections.secondaryLight" }
  } else if (color === "tertiary") {
    return { variant: "sections.tertiary" }
  } else if (color === "tertiaryLight") {
    return { variant: "sections.tertiaryLight" }
  } else if (color === "none") {
    return null
  } else {
    return { bg: color }
  }

  return null
}

module.exports = getSectionVariant;
