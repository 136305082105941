/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import logoImage from "../../media/images/logo.svg"

const Logo = ({ closeNav }) => (
  <div
    sx={{
      display: "flex",
      alignItems: "center",
      flex: "1 0 auto",
      my: 0,
      mx: 0,
      fontSize: "0",
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        maxHeight: "60px",
      },
    }}
    onClick={closeNav}
    className="logo-wrapper"
  >
    <Link
      sx={{
        flex: "1 1 auto",
        display: "block",
        borderBottom: "none !important",
        textDecoration: "none !important",
        "&:hover": {
          textDecoration: "none !important",
          borderBottom: "none !important",
        },
        "&:after": {
          content: "'Trust'",
          position: "relative",
          top: "-1px",
          left: "3px",
          fontWeight: "300",
          fontSize: "16px",
          color: "primary",
        },
      }}
      to="/"
    >
      <span sx={{ position: "absolute", fontSize: "0px" }}>Homepage</span>
      <img
        src={logoImage}
        alt="Okta logo"
        height="34"
        width="100"
        sx={{ m: 0, display: "inline" }}
      />
    </Link>
  </div>
)

export default Logo
