/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Container } from "components"
import dictionary from "./dictionary"

const Footer = ({ lang }) => {
  const { links } = dictionary;

  return (
    <div
      className="Footer"
      sx={{
        variant: "sections.primary",
        py: ["spacing3", null, null, "spacing5", "spacing7"],
      }}
    >
      <Container>
        <div
          className="Footer-content"
          sx={{
            display: "flex",
            flexDirection: ["column", null, null, null, null, "row"],
            alignItems: ["flex-start", null, null, null, null, "flex-end"],
            justifyContent: "space-between",
            textAlign: ["left", null, null, null, null, "left"],
          }}
        >
          <ul
            sx={{
              display: "flex",
              justifyContent: [
                "flex-start",
                null,
                null,
                null,
                null,
                "space-between",
              ],
              alignItems: "center",
              flexWrap: ["wrap", null, null, null, null, "no-wrap"],
              flex: ["1 1 auto", null, null, null, null, "0 1 auto"],
              pl: 0,
              my: ["spacing5", null, null, null, null, 0],
              mx: [0, null, null, null, null, "spacing3"],
              pb: ["spacing5", null, null, null, null, 0],
              width: ["100%", null, null, null, null, "auto"],
              borderBottom: ["1px solid", null, null, null, null, "none"],
              borderTopColor: "gray700",
              borderBottomColor: "gray700",

              "& > li": {
                flex: "0 1 auto",
                pr: "spacing2",
                mr: "spacing2",
                listStyleType: "none",
                fontSize: "16px",
                display: "block",
                lineHeight: "1",
                borderRight: "solid 1px",
                borderColor: "white",
              },

              "& > li:first-of-type": {
                ml: "0",
              },

              "& > li:last-of-type": {
                borderRight: "solid 0px",
              },

              "& > li a": {
                fontSize: "16px",
              },
            }}
          >
            {links?.map(link => {
              let href = link.url.href
              const isInternal = href.startsWith("/")
              href =
                isInternal && language !== "EN"
                  ? `/${language.toLowerCase()}${href}`
                  : href
              return (
                <li>
                  <a sx={{ variant: "links.secondary" }} href={href}>
                    {link.label}
                  </a>
                </li>
              )
            })}
          </ul>

          <div
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: ["center", null, null, null, null, "flex-end"],
              width: ["100%", null, null, null, null, "auto"],
            }}
          >
            <div
              sx={{
                textAlign: "right",
              }}
            >
              <p sx={{ variant: "text.caption", fontSize: "16px", mt: "3px" }}>
                {dictionary.legal}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
