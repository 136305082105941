/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

const Columns = ({ children, horizontalAlignment, verticalAlignment, overrideStyles }) => {
  const stretchColumnItemWidth = horizontalAlignment === "stretch" ? {
    "& > [class*='ColumnItem']": {
      flexGrow: "1",
      width: "auto",
      maxWidth: "none",
      minWidth: 0,
    }
  } : null
  return (
    <div sx={{
      display: "flex",
      flexWrap: "wrap",
      alignItems: verticalAlignment,
      justifyContent: horizontalAlignment,
      variant: "gutters.columns",
      ...stretchColumnItemWidth,
      ...overrideStyles,
    }}>{children}</div>
  )
}

Columns.propTypes = {
}

export default Columns
