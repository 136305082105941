const siteUrl = `https://www.okta.com${process.env.GATSBY_PATH_PREFIX}`
const metaImage =  require("../../media/images/favicon.png");
const headline = process.env.GATSBY_SITE_METADATA_TITLE
const siteLanguage = "en"
const author = "Okta, Inc."
const currentUrl = siteUrl
const metaDescription = process.env.GATSBY_SITE_METADATA_DESCRIPTION
const created = "4/26/21"
const updated = "4/26/21"

export const schemaOrgWebPage = {
  "@context": "http://schema.org",
  "@type": "WebPage",
  url: siteUrl,
  headline,
  inLanguage: siteLanguage,
  mainEntityOfPage: siteUrl,
  description: metaDescription,
  name: headline,
  author: {
    "@type": "Organization",
    name: author,
  },
  copyrightHolder: {
    "@type": "Organization",
    name: author,
  },
  copyrightYear: "2021",
  creator: {
    "@type": "Organization",
    name: author,
  },
  publisher: {
    "@type": "Organization",
    name: author,
  },
  datePublished: created,
  dateModified: updated,
  image: {
    "@type": "ImageObject",
    url: `${metaImage}`,
  },
}

export const schemaOrganization = {
  "@context": "http://schema.org",
  "@type": "Organization",
  legalName: "Okta, Inc.",
  name: "Okta, Inc.",
  url: siteUrl,
  contactPoint: [
    {
      "@type": "ContactPoint",
      telephone: "+1 (888) 722-7871",
      contactType: "Customer Service",
    },
    {
      "@type": "ContactPoint",
      telephone: "+1 (800) 588-1656",
      contactType: "Sales USA",
    },
  ],
  logo: "https://www.okta.com/themes/custom/okta_www_theme/images/logo.svg",
  sameAs: [
    "https://twitter.com/okta",
    "https://www.facebook.com/Okta/",
    "https://www.linkedin.com/company/okta-inc-/",
    "https://www.youtube.com/user/OktaInc",
  ],
}

export const schemaEvent = {
  "@context" : "http://schema.org",
  "@type" : "Event",
  "name" : "Forum21 Europe",
  "startDate" : "Jun 23 2021",
  "endDate" : "Jun 23 2021",
  "location": [
    {
      "@type": "VirtualLocation",
      "url": siteUrl
    }
  ],
  "organizer": schemaOrganization,
  "performer": [
      schemaOrganization,
      {
        "@type": "Person",
        "name": "Philipp Diekhöner"
      },
      {
        "@type": "Person",
        "name": "David McClelland"
      },
      {
        "@type": "Person",
        "name": "Duncan Brown"
      },
      {
        "@type": "Person",
        "name": "Derek Gordon"
      },
      {
        "@type": "Person",
        "name": "Angela Salmeron"
      },
      {
        "@type": "Person",
        "name": "Rob Hale"
      },
      {
        "@type": "Person",
        "name": "Liz Cocker"
      },
      {
        "@type": "Person",
        "name": "Yves Couturier"
      },
      {
        "@type": "Person",
        "name": "Reem Zahran"
      },
      {
        "@type": "Person",
        "name": "Todd McKinnon"
      },
      {
        "@type": "Person",
        "name": "Jesper Frederiksen"
      },
      {
        "@type": "Person",
        "name": "Ben King"
      },
      {
        "@type": "Person",
        "name": "Ian Lowe"
      },
      {
        "@type": "Person",
        "name": "Jiong Liu"
      },
  ],
  // "offers": {
  //   "@type": "Offer",
  //   "price": "1195.00",
  //   "priceCurrency": "USD",
  //   "url": siteUrl,
  //   "availability": "http://schema.org/InStock",
  // },
  "image": "https://www.okta.com/sites/default/files/media/image/2021-04/Forum21_Europe.png?v=1",
  "description": metaDescription,
  "url" : siteUrl,
  "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode"
}
