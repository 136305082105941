/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState } from "react"
import { Image } from "components"
import icon from "../../media/images/info.png"
import PropTypes from "prop-types"

const Tooltip = ({ children, overrideStyles, tooltip }) => {
  const [visibility, setVisibility] = useState(false)

  const wrapperStyles = {
    position: "relative",
    flex: "1 0 100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    mt: "spacing3",
    pt: "spacing3",
    borderTop: "1px solid",
    borderTopColor: "gray200",
    minWidth: ["100%", null, "300px"],

    ...overrideStyles,
  }

  const tipStyles = {
    position: "absolute",
    display: "block",
    minWidth: ["100%", null, "300px"],
    width: "100%",
    left: 0,
    top: "105%",
    bg: "gray100",
    p: "spacing4",
    zIndex: 400,
    textAlign: "left",
    variant: "text.caption",
    lineHeight: "150%",
    transition: "opacity 0.25s ease-in",
  }

  const visibleStyle = {
    opacity: 1,
    pointerEvents: "auto",
  }

  const hiddenStyle = {
    opacity: 0,
    pointerEvents: "none",
  }

  const styles = visibility ? visibleStyle : hiddenStyle

  return (
    <div
      sx={{
        ...wrapperStyles,
      }}
    >
      {children}
      <span
        sx={{
          cursor: "pointer",
          ml: "spacing1",
          "&:after": {
            display: "block",
            opacity: visibility ? "1" : "0",
            content: "''",
            position: "relative",
            bottom: "-10%",
            right: "-50%",
            transform: "translateX(-50%)",
            height: 0,
            width: 0,
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderBottom: "8px solid",
            borderBottomColor: "gray100",
            transition: "opacity 0.25s ease-in",
          },
        }}
        onClick={() => {
          setVisibility(!visibility)
        }}
      >
        <Image alt="tooltip icon" height="20" width="20" src={icon} />

        <div
          sx={{
            ...tipStyles,
            ...styles,
          }}
        >
          {tooltip}
        </div>
      </span>
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.node.isRequired,
  overrideStyles: PropTypes.shape({}),
}

export default Tooltip
