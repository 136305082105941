/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { darken, lighten } from "@theme-ui/color"
import PropTypes from "prop-types"
import { Button, LoginButton, NavItem, RegistrationButton } from "components"

const Nav = ({ navItems, closeNav, overrideStyles }) => {
  const subMenuStyles = {
    display: ["block", null, null, "flex"],
    pointerEvents: ["auto", null, null, "none"],
    position: ["relative", null, null, "absolute"],
    pt: [0, null, null, "50px"],
    my: ["spacing2", null, null, "20px"],
    ml: ["2px", null, null, "auto"],
    opacity: [1, null, null, 0],
    left: ["auto", null, null, "50%"],
    transform: [null, null, null, "translate3d(-50%, -5px, 0)"],
    transition: ["none", null, null, "transform 0.25s ease"],

    "&:hover": {
      pointerEvents: "auto",
      opacity: 1,
      transform: [null, null, null, "translate3d(-50%, 0px, 0)"],
    },
  }

  const subMenuInnerStyles = {
    position: "relative",
    flex: "1 1 auto",
    minWidth: [0, null, null, "260px"],
    bg: ["none", null, null, "secondary"],
    alignItems: ["flex-start", null, null, "initial"],
    borderLeft: ["3px solid", null, null, "none"],
    borderLeftColor: "primary",
    display: ["flex", null, null, "block"],
    flexDirection: "column",
    pt: ["1rem", null, null, "1.5rem"],
    pr: ["0", null, null, "1.5rem"],
    pb: "0.5rem",
    pl: "1.5rem",
    borderRadius: [0, null, null, "5px"],
    left: "50%",
    transform: "translateX(-50%)",

    "&:before": {
      display: ["none", null, null, "block"],
      content: "''",
      position: "absolute",
      top: "-12px",
      left: "50%",
      left: "calc(50% - 1px)",
      transform: "translate3d(-50%, 0, 0)",
      width: "0",
      height: "0",
      borderStyle: "solid",
      borderWidth: "0 10px 15px 10px",
      borderTopColor: "transparent",
      borderRightColor: "transparent",
      borderBottomColor: "secondary",
      borderLeftColor: "transparent",
    },
  }

  return (
    <div
      sx={{
        display: "flex",
        flex: "1 1 auto",
        alignItems: "center",
        ...overrideStyles,
      }}
    >
      <nav
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: ["column", null, null, "row"],
          alignItems: ["flex-start", null, null, "center"],
          width: ["100%", null, null, "auto"],
          flex: ["0 1 auto", null, null, "1 1 auto"],
          justifyContent: "flex-end",
        }}
      >
        {navItems &&
          navItems.map((menuItem, i) => {
            const hasSubMenu =
              Array.isArray(menuItem?.submenu) &&
              menuItem.submenu &&
              menuItem.submenu.length > 0
            return (
              <div
                sx={{
                  flex: "0 1 auto",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  alignItems: ["flex-start", null, null, "center"],
                  justifyContent: "flex-start",
                  mb: hasSubMenu ? 0 : ["spacing2", null, null, "0"],
                  pl: "spacing3",

                  "&:hover": {
                    "& > a > span": {
                      svg: {
                        transform: "rotate(-180deg)",
                        path: {
                          stroke: "linkPrimaryHoverColor",
                          "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)":
                            {
                              stroke: "#1662dd",
                            },
                        },
                      },
                    },

                    "& > div": {
                      pointerEvents: "auto",
                      opacity: 1,
                      transform: [
                        null,
                        null,
                        null,
                        "translate3d(-50%, 0px, 0)",
                      ],
                    },
                  },
                }}
                className="navItem-wrapper"
                key={menuItem.label}
              >
                <NavItem
                  closeNav={closeNav}
                  title={menuItem.label}
                  href={menuItem.url?.href}
                  external={!menuItem.url?.internal}
                  fragment={menuItem.options?.fragment}
                  relative={menuItem.relative}
                  submenu={hasSubMenu ? true : false}
                />

                {hasSubMenu && (
                  <div sx={subMenuStyles}>
                    <div sx={subMenuInnerStyles}>
                      {menuItem.submenu.map((item, i) => {
                        return (
                          <div key={item.id}>
                            <NavItem
                              closeNav={closeNav}
                              title={item.title}
                              href={item.href}
                              relative={item.relative}
                              external={item.external}
                              overrideStyles={{
                                display: "inline-block",
                                mb: "spacing2",
                                whiteSpace: "nowrap",
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
      </nav>

      <div
        sx={{
          button: {
            "&[class*='Button-primary-regular']": {
              mb: 0,
            },
            "&[class*='Button-secondary-regular']": {
              mb: 0,
            },
          },
        }}
      >
        <RegistrationButton
          classes="Register-button"
          triggerElement="button"
          buttonSize="regular"
          buttonStyle="primary"
          title="Register"
        />
        <LoginButton
          triggerElement="button"
          buttonSize="regular"
          buttonStyle="secondary"
          title="Login"
        />
      </div>
    </div>
  )
}

Nav.propTypes = {
  navitems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Nav
