/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Image = ({ alt, alignment, caption, src, width, height, widthOverride, overrideStyles }) => {

  const alignImage = () => {
    if (alignment === "center") {
      return {
        justifyContent: "center"
      }
    }

    if (alignment === "right") {
      return {
        justifyContent: "flex-end"
      }
    }

    return null
  }

  const wrapperStyles = alignImage()

  const styles = {
    flex: "1 1 auto",
    maxWidth: widthOverride ? widthOverride : null,
  }

  if (src.childImageSharp) {
    const image = getImage(src)
    return (
      <div sx={{
        display: "flex",
        flex: "1 1 auto",
        width: "100%",
        ...wrapperStyles
      }}>
        <div sx={{
          ...styles
        }}>
          <GatsbyImage image={image} alt={alt} sx={{ ...overrideStyles }} />
          {caption && <small>{caption}</small>}
        </div>
      </div>
    )
  }

  if (src.publicURL) {
    return (
      <div sx={{
        display: "flex",
        flex: "1 1 auto",
        width: "100%",
        ...wrapperStyles
      }}>
        <div sx={{
          ...styles
        }}>
          <img src={src.publicURL} alt={alt} width={width} height={height} />
          {caption && <small>{caption}</small>}
        </div>
      </div>
    )
  }

  return (
    <div sx={{
      display: "flex",
      flex: "1 1 auto",
      width: "100%",
      ...wrapperStyles
    }}>
      <div sx={{
        ...styles
      }}>
        <img src={src} alt={alt} width={width} height={height} />
        {caption && <small>{caption}</small>}
      </div>
    </div>
  )
}

Image.propTypes = {
}

export default Image
