/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { ErrorMessage } from '@hookform/error-message';
import { jsx } from "theme-ui"
import {
  FieldWrapperStyles,
  SelectStyles,
  LabelStyles,
} from "./FormElementStyles"
import Tooltip from "./Tooltip"

const Select = ({
  id,
  name,
  label,
  onChange,
  options,
  value,
  required,
  overrideStyles,
  fullWidth,
}) => {
  const { register, errors } = useFormContext()
  const errorHighlight = Object.keys(errors).length === 0 ? null : { borderColor: "inputError", outline: "2px solid", outlineColor: "inputError", }
  return (
    <div
      sx={{
        width: fullWidth ? "100%" : "50%",
        ...FieldWrapperStyles,
        ...overrideStyles,
        "select": {
          flexDirection: "row",
        }
      }}
    >
      {label && (
        <label
          sx={{
            ...LabelStyles,
          }}
          htmlFor={id || name}
        >
          {label}
        </label>
      )}
      <select
        name={name}
        defaultValue={value}
        onChange={onChange}
        ref={register({
          required: {
            value: required,
            message: `This field is required.`,
          },
        })}
        sx={{
          ...SelectStyles,
          ...errorHighlight
        }}
      >
        {options.map((option) => (
          <option value={option.value} key={option.label}>
            {option.label}
          </option>
        ))}
      </select>
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <Tooltip color='danger'>{message}</Tooltip>}
        />
      )}
    </div>
  )
}

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  overrideStyles: PropTypes.shape({}),
}
Select.defaultProps = {
  required: false,
  value: "",
  id: null,
  fullWidth: false,
  overrideStyles: null,
  onChange: null,
}

export default Select
