/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

const HamburgerMenu = ({ closeNav, isOpen, openNav }) => {
  const toggleMenuKeyPress = (e) => {
    if (isOpen && e.key === "Escape") {
      closeNav()
    } else if (!isOpen && e.key === "Enter") {
      openNav()
    }
  }

  const toggleMenu = () => {
    if (isOpen) {
      closeNav()
    } else {
      openNav()
    }
  }

  const lineStyles = {
    width: "100%",
    borderBottom: "4px solid",
    borderBottomColor: "primary",
    margin: "auto 0",
    transition: "opacity 0.35s ease-in-out, transform 0.25s ease-in-out",
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      borderBottomColor: "#00297A",
    }
  }

  return (
    <div
      tabIndex={0}
      role="button"
      aria-label="Main navigation menu button"
      onKeyDown={toggleMenuKeyPress}
      onClick={toggleMenu}
      sx={{
        display: ["block", null, null, "none"],
        position: "relative",
        cursor: "pointer",
        "&:focus": {
          outlineColor: "primary"
        }
      }}>
      <div
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: "50px",
          width: "50px",
          mr: "-10px",
          p: "10px",
        }}>
        <div
          sx={{
            ...lineStyles,
            opacity: isOpen ? 0 : 1,
            transform: isOpen ? "translate3d(0, 5px, 0) scale(0.9)" : "translate3d(0, 0px, 0) scale(1)", }} />
        <div sx={{ ...lineStyles, transform: "translate3d(0, 0px, 0) scale(1)", }} />
        <div
          sx={{
            ...lineStyles,
            opacity: isOpen ? 0 : 1,
            transform: isOpen ? "translate3d(0, -5px, 0) scale(0.9)" : "translate3d(0, 0px, 0) scale(1)", }} />
      </div>
    </div>
  )
}

HamburgerMenu.propTypes = {
}

export default HamburgerMenu
