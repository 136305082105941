/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

const CTAGroup = ({
  children,
  flexDirection = "row",
  alignItems = "flex-start",
}) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: flexDirection,
        justifyContent: flexDirection === "row" ? alignItems : "center",
        alignItems: flexDirection === "row" ? "center" : alignItems,

        ".Button": {
          "&:last-of-type": {
            mb: flexDirection === "column" ? 0 : null,
          },
          "& + .Button": {
            ml:
              flexDirection === "column" &&
              (alignItems === "flex-start" || alignItems === "center")
                ? 0
                : null,
          },
        },

        ...(flexDirection === "column" && {
          ".Button, .CustomLink": {
            mb: "12px",
          },

          ".Button + .Button, .CustomLink + .CustomLink": {
            ml: 0,
          },
        }),
      }}
    >
      {children}
    </div>
  )
}

CTAGroup.propTypes = {}

export default CTAGroup
