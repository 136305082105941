/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

const Container = ({ children, overrideStyles }) => {
  return (
    <div sx={{
      width: "100%",
      maxWidth: ["100%", null, null, "1280px"],
      mx: "auto",
      px: ["spacing2", null, null, "spacing4", null, 0],

      ".Container": {
        padding: 0,
      },
      ...overrideStyles
    }}>{children}</div>
  )
}

Container.propTypes = {
}

export default Container
