/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { HeadingTag, Image, WYSIWYG } from "components"

const BasicContent = ({
  title,
  headerTag,
  body,
  image,
  hasInlineImages,
  imagePosition,
  children,
}) => {

  const alignImage = () => {
    if (imagePosition === "left") {
      return {
        alignItems: "flex-start"
      }
    }
    if (imagePosition === "right") {
      return {
        alignItems: "flex-end"
      }
    }
    return null
  }

  const imageWrap = () => {
    if (imagePosition === "left" || imagePosition === "right") {
      return {
        display: "flex",
        flexDirection: ["column", null, null, "row"]
      }
    }
    return null
  }

  const imageAlign = alignImage()
  const wrapperAlign = imageWrap()

  return (
    <div className="BasicContent"
      sx={{
        ...wrapperAlign
      }}
    >
      {image && (
        <div sx={{ width: "195px", mr: "25px", img: { height: "95px", width: "auto", mb: "spacing3", ta: "left", ml: 0 }, }}>
          <Image
            src={image.entity?.gatsbyImageFile?.childImageSharp ? image.entity.gatsbyImageFile : image.entity.fieldMediaImage.url}
            overrideStyles={{
              width: "150px",
              mb: "spacing3",
              maxWidth: "120px",
              ...imageAlign
            }}
          />
        </div>
      )}
      {title && (
        <HeadingTag
          tag={headerTag}
          text={title}
          overrideStyles={{ mb: "spacing3", w: "100%" }}
        />
      )}
      <WYSIWYG
        {...(hasInlineImages ? { children: body } : { content: body })}
        overrideStyles={{
          p: { mb: "spacing2" },
          "p img": { mt: "spacing2" },
          ".gatsby-image-wrapper": { mt: "spacing2", img: { mt: 0 } },
        }}
      />
      {children && (
        <div
          sx={{
            pt: "7px",
            mt: "auto",
            display: "flex",
            alignItems: "center",
            ".Button + .Button, .CustomLink + .CustomLink": {
              marginLeft: "spacing3",
            },
          }}
        >
          {children}
        </div>
      )}
    </div>
  )
}

BasicContent.propTypes = {
  body: PropTypes.node,
  title: PropTypes.string,
  image: PropTypes.object,
  hasInlineImages: PropTypes.bool,
  imagePosition: PropTypes.string,
  children: PropTypes.node,
}

export default BasicContent
