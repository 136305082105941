/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { Container } from "components"
import { getSectionPadding, getSectionVariant } from "helpers"

const Section = ({
  backgroundColor = "",
  children,
  container = true,
  id = null,
  paddingTop = "",
  paddingBottom = "",
  overrideStyles,
  dividers,
}) => {
  const bgColorVariant = getSectionVariant(backgroundColor)
  const styles = {
    position: "relative",
    display: "flex",
    flex: "1 1 auto",
    // contentVisible: "auto",
    // TODO: Review if this style is needed
    // "h2.HeadingTag": {
    //   display: "inline-block",
    //   "&:before": {
    //     content: "''",
    //     display: "block",
    //     borderTop: "6px solid",
    //     mb: "spacing2",
    //   }
    // },
    ...bgColorVariant,
    ...overrideStyles,
  }
  const styles2 = {
    pt: getSectionPadding(paddingTop),
    pb: getSectionPadding(paddingBottom),
    ...(dividers?.includes("bottom") && {
      borderBottom: "1px solid",
      borderColor: "gray200",
    }),
    ...(dividers?.includes("top") && {
      borderTop: "1px solid",
      borderColor: "gray200",
    }),
  }

  if (container) {
    return (
      <div id={id} sx={{ ...styles }}>
        <Container overrideStyles={{ ...styles2 }}>{children}</Container>
      </div>
    )
  }

  return (
    <div id={id} sx={{ ...styles, ...styles2 }}>
      {children}
    </div>
  )
}

Section.propTypes = {}

export default Section
