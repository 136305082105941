/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

const LogoIcon = ({ color = "primary", overrideStyles }) => (
  <svg x="0px" y="0px" viewBox="0 0 412.4 135.3"
    sx={{
      ml: 0,
      mr: "auto",
      maxWidth: "100%",
      ...overrideStyles
    }}
    alt="Forum21 Europe Logo">
    <path sx={{ fill: color }} d="M27.2,19.4c0-3.4,1.5-5,5-5h10.6V1.5H25.3c-9.2,0-14.2,6-14.2,14.9v7.9H0v12.3h11.1v44.7h16.2V36.6h13.2
      V24.3H27.2V19.4z M103,52.8c0-18.2-12.5-30.2-30-30.2s-30,12-30,30.2c0,17.5,12.5,30.3,30,30.3S103,71.3,103,52.8z M73.1,70.2
      C65.3,70.2,60,64.7,60,53s5.4-17.7,13.1-17.7S86.2,41.3,86.2,53S80.8,70.2,73.1,70.2z M128.4,37.3v-13h-15.8v57h16.2V58.4
      c0-14.2,5.1-18.8,16.8-18.8c1.9,0,4,0.1,6.3,0.3V23.2c-0.9-0.1-1.7-0.1-2.6-0.1C135.4,23.1,130.4,32.6,128.4,37.3z M406.4,1.5
      c-5.7,10.1-14.7,16.3-23.8,19v8.5c8.8-2,17.3-8.1,21.8-15.6v67.8h8V1.5H406.4z M365.5,46.4c5-6.4,8-13.7,8-22
      c0-14.1-9.6-24.4-25.2-24.4c-14.6,0-25.1,9.6-25.1,25.5l8.1,1c-0.1-13.6,8.5-19.3,17-19.3c9,0,16.6,5.9,16.6,18
      c0,18.4-21.5,34.2-41.3,47.1v8.9h51.1v-7.5H333C347,64.9,358.2,56.1,365.5,46.4z M291.6,22.6c-7.4,0-13.9,2.5-19.1,9.9
      c-3.3-5.8-8.7-9.9-16.3-9.9c-7.1,0-13.3,3.1-17.1,9.9v-8.2h-15.7v57h16.2V52.2c0-5.7,0.2-16.3,10.5-16.3c5.4,0,8.9,3.1,8.9,10.7
      v34.7h16V52.2c0-5.7,0.2-16.3,10.5-16.3c5.4,0,8.9,3.1,8.9,10.7v34.7h16.2V43.2C310.4,28.3,302.2,22.6,291.6,22.6z M194.8,52.4
      c0,9.3-3.1,17.2-11.4,17.2c-5.2,0-8.6-3.1-8.6-10.7V24.3h-16.2v38.4c0,12.4,6.7,20.4,17.6,20.4c10.9,0,16.1-6.7,18.6-10.9v9.1H211
      v-57h-16.2L194.8,52.4L194.8,52.4z M11.1,134.6h22.4v-4.8H16.7v-8.5h12.6v-4.8H16.7v-7.5h16.8v-4.8H11.1V134.6z M62.8,121.9
      c0,5.2-2.2,8.4-7.2,8.4c-4.9,0-7.3-3.1-7.3-8.4v-17.8h-5.7v18.7c0,8,5,12.5,13,12.5c7.9,0,12.9-4.6,12.9-12.5v-18.7h-5.7L62.8,121.9
      L62.8,121.9z M96.4,120.6c3.8-0.7,7.6-3.2,7.6-8.4c0-4.8-3.8-8.2-10.2-8.2H79.6v30.6h5.7v-12h5.8c3.9,0,5.7,1.4,6.3,5.1l1.1,7h6.1
      l-1.4-7.5C102.4,122.9,100.3,121.1,96.4,120.6z M92.8,117.8h-7.5v-8.9h7.5c3,0,5.3,1.4,5.3,4.4C98.1,116.4,95.8,117.8,92.8,117.8z
       M127.8,103.4c-9.6,0-15.9,6.7-15.9,16c0,9.3,6.2,15.9,15.9,15.9s15.9-6.6,15.9-15.9C143.7,110.1,137.4,103.4,127.8,103.4z
       M127.8,130.4c-6.2,0-9.9-4.7-9.9-11c0-6.4,3.7-11.1,9.9-11.1s9.9,4.7,9.9,11.1C137.7,125.7,134.1,130.4,127.8,130.4z M166.3,104.1
      h-13.1v30.6h5.7v-11.2h7.5c6.5,0,10.7-3.9,10.7-9.7C177,107.8,172.8,104.1,166.3,104.1z M165.3,118.6h-6.5v-9.7h6.5
      c3.6,0,5.8,1.9,5.8,4.8C171,116.7,168.9,118.6,165.3,118.6z M185.6,134.6H208v-4.8h-16.8v-8.5h12.6v-4.8h-12.6v-7.5H208v-4.8h-22.4
      V134.6z" />
  </svg>
)

LogoIcon.propTypes = {
}

export default LogoIcon
