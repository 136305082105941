const getSectionPadding = (padding) => {
  if (padding === "none") {
    return "spacing0"
  }
  if (padding === "small") {
    return ["spacing4", null, null, "spacing6"]
  }
  if (padding === "large") {
    return ["spacing8", null, null, "spacing9"]
  }

  return ["spacing6", null, null, "spacing8"]
}

module.exports = getSectionPadding;
