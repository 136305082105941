/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Container, HamburgerMenu, Logo, Nav } from "components"
import { freezeWindow, unfreezeWindow } from "helpers"
import dictionary from "./dictionary"

const breakpoint = 1024
const scrolldistance = 100

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileNavIsOpen: false,
      windowWidth: null,
    }

    this.headerRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener("resize", this.throttledResize, false)
    window.addEventListener("scroll", this.throttledScroll, false)

    this.handleSetWindowWidth()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledScroll, false)
    window.removeEventListener("resize", this.throttledResize, false)
  }

  handleSetWindowWidth = () => {
    if (window.innerWidth >= breakpoint) {
      this.setState(
        {
          windowWidth: window.innerWidth,
        },
        this.closeNav
      )
    } else {
      this.setState({
        windowWidth: window.innerWidth,
      })
    }
  }

  openNav = () => {
    const { windowWidth } = this.state
    if (windowWidth > breakpoint) {
      const header = this.headerRef.current
      header.classList.add("no-interaction")

      setTimeout(() => {
        header.classList.remove("no-interaction")
      }, 100)
    }

    if (windowWidth <= breakpoint) {
      const { mobileNavIsOpen } = this.state
      if (mobileNavIsOpen === false) {
        this.setState(
          {
            mobileNavIsOpen: true,
          },
          freezeWindow
        )
      }
    }
  }

  closeNav = () => {
    if (this.state.mobileNavIsOpen) {
      this.setState(
        {
          mobileNavIsOpen: false,
        },
        unfreezeWindow
      )
    }
  }

  handleScroll = () => {
    const { windowWidth } = this.state
    const winScroll = window.scrollY
    const headerRef = this.headerRef.current
    const hasClass = headerRef?.classList?.contains("is-scrolled")

    if (headerRef && windowWidth >= breakpoint) {
      if (winScroll > scrolldistance && !hasClass) {
        headerRef.classList.add("is-scrolled")
      } else if (winScroll < scrolldistance && hasClass) {
        headerRef.classList.remove("is-scrolled")
      }
    }
  }

  throttledScroll = () => {
    window.requestAnimationFrame(this.handleScroll)
  }

  throttledResize = () => {
    window.requestAnimationFrame(this.handleSetWindowWidth)
  }

  render() {
    const { lang } = this.props
    const { mobileNavIsOpen, windowWidth } = this.state

    const navBaseStyles = {
      position: ["fixed", null, null, "relative"],
      visibility: ["hidden", null, null, "visible"],
      display: "flex",
      flex: ["1 0 100%", null, null, "1 1 auto"],
      alignItems: ["flex-start", null, null, "center"],
      flexDirection: ["column", null, null, "row"],
      marginTop: ["2rem", null, null, "0"],
      marginBottom: ["6rem", null, null, "0"],
      minHeight: 0,
      opacity: ["0", null, null, "1"],
    }

    const navOpenStyles = {
      ...navBaseStyles,
      position: ["relative", null, null, "relative"],
      visibility: "visible",
      opacity: ["1", null, null, "1"],
      minHeight: "100vh",
    }

    const addedNavStyles =
      windowWidth < breakpoint && mobileNavIsOpen
        ? navOpenStyles
        : navBaseStyles

    return (
      <header
        sx={{
          variant: "sections.secondary",
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          top: "0",
          right: "0",
          left: "0",
          zIndex: "999",
          boxShadow: "rgb(0 0 0 / 10%) 0 0 35px",

          "&.no-interaction": {
            pointerEvents: "none",
            userSelect: "none",
          },
        }}
        ref={this.headerRef}
      >
        <Container>
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              py: ["10px", null, null, "1.7rem"],
              flexWrap: "wrap",
            }}
          >
            <div
              sx={{
                display: "flex",
                justifyContent: ["space-between", null, null, "center"],
                alignItems: "center",
                minWidth: "120px",
                width: ["100%", null, null, "auto"],
                mr: ["auto", null, null, "spacing3", null, "spacing6"],
                "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)":
                  {
                    flex: "0 1 auto",
                  },
              }}
            >
              <Logo closeNav={this.closeNav} />
              <HamburgerMenu
                isOpen={mobileNavIsOpen}
                openNav={this.openNav}
                closeNav={this.closeNav}
              />
            </div>
            <Nav
              navItems={dictionary.links}
              closeNav={this.closeNav}
              overrideStyles={addedNavStyles}
            />
          </div>
        </Container>
      </header>
    )
  }
}

Header.propTypes = {}

export default Header
