/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { CustomLink, DropDownIcon } from "components"

const NavItem = ({
  external,
  fragment,
  href,
  relative,
  closeNav,
  submenu,
  title,
  overrideStyles,
}) => {
  const submenuStyles = submenu
    ? {
        display: "flex",
        alignItems: "center",
        flex: ["1 1 auto", null, null, "0 1 auto"],
      }
    : null

  const submenuIconStyles = {
    display: ["none", null, null, "flex"],
    ml: "spacing1",
  }

  if (href === null) {
    return (
      <span
        className="CustomLink"
        onClick={closeNav}
        sx={{
          variant: "links.mainNavLinks",
          cursor: "pointer",
          ...submenuStyles,
          ...overrideStyles,
        }}
      >
        {title}
        {submenu && (
          <span sx={submenuIconStyles}>
            <DropDownIcon color="primary" />
          </span>
        )}
      </span>
    )
  }

  if (fragment) {
    const cleanedHref = relative
      ? `${relative}#${fragment}`
      : `${href}#${fragment}`
    return (
      <CustomLink
        onClickFunction={closeNav}
        external={external}
        href={cleanedHref}
        style="mainNavLinks"
        overrideStyles={{ ...submenuStyles, ...overrideStyles }}
      >
        {title}
        {submenu && (
          <span sx={submenuIconStyles}>
            <DropDownIcon color="primary" />
          </span>
        )}
      </CustomLink>
    )
  }

  return (
    <CustomLink
      onClickFunction={closeNav}
      external={external}
      href={href}
      style="mainNavLinks"
      overrideStyles={{
        variant: "links.mainNavLinks",
        ...submenuStyles,
        ...overrideStyles,
      }}
    >
      {title}
      {submenu && (
        <span sx={submenuIconStyles}>
          <DropDownIcon color="primary" />
        </span>
      )}
    </CustomLink>
  )
}

NavItem.propTypes = {}

export default NavItem
