/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react";
import PropTypes from "prop-types";
import { HeadingTag, WYSIWYG } from "components"

const Card = ({ body, ctaUrl, ctaText, display, kicker, tag, title, overrideStyles }) => {
  const cardWrapperStyles = {
    bg: "primary",
    borderRadius: "0px 32px",
    display: "flex",
    py: "spacing4",
    px: "spacing4",
    width: "100%",
    variant: "sections.primary",
    minHeight: ["auto", null, "300px", "333px"],
    height: "100%",
    boxShadow: ctaUrl ? "0px 0px 0px transparent" : null,
    transition: ctaUrl ? "box-shadow 0.25s ease" : null,
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      bg: "#00297A",
    },

    "&:hover": {
      boxShadow: ctaUrl ? "0px 4px 8px #8C8C96" : null,
    },

    ...overrideStyles
  }

  const cardContents = <div className="Card-paragraph_content-wrapper" sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
          flex: "0 1 100%",
        }
      }}>
        {
          title && <div sx={{ mb: "spacing2" }}>
            {kicker && <h6 className="Card-paragraph_header-super">{kicker}</h6>}
            {title && (
              <HeadingTag display={display} text={title} tag={tag} />
            )}
          </div>
        }

        {body &&
          <div className="Card-paragraph_body">
            <WYSIWYG content={body} />
          </div>
        }

        {ctaUrl && <div sx={{ mt: "auto", mb: 0, pt: "spacing3" }}><span className="Card-cta-link" sx={{ variant: "links.secondary" }}>{ctaText}</span></div>}
      </div>

  if (ctaUrl) {
    return (
      <div sx={{ ...cardWrapperStyles }}>
      <a sx={{ variant: "links.wrapper", display: "flex" }} href={ctaUrl}>{cardContents}</a>
      </div>
    )
  }

  return (
    <div sx={{ ...cardWrapperStyles }}>
      {cardContents}
    </div>
  )
}

Card.propTypes = {
}

export default Card
