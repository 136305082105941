/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { ModalTrigger, LoginModal } from "components"
import { componentResolver, oktaEnvCheck } from "helpers"

const LoginButton = ({ title, customContent, triggerElement, buttonSize, buttonStyle }) => {
  if (oktaEnvCheck("GATSBY_HEADER_SHOW_LOGIN_BUTTON") === "true") {
    const components = componentResolver(customContent)
    return (
      <ModalTrigger
        triggerElement={triggerElement}
        buttonSize={buttonSize}
        buttonStyle={buttonStyle}
        modalContent={
           <LoginModal
            customContent={
              <div>
                {components &&
                components.map((item) => {
                  return (
                    <React.Fragment key={item.props.component.entityUuid}>
                      {item}
                    </React.Fragment>
                  )
                })}
              </div>
            }
           />
        }
      >
        {title}
      </ModalTrigger>
    )
  }

  return null
}

LoginButton.propTypes = {
}

export default LoginButton
