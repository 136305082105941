/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useEffect } from "react"
import { createPortal } from "react-dom";
import PropTypes from "prop-types"

const Portal = ({ children, elementName = "portal" }) => {
  let portalRoot = document.getElementById(elementName);

  if (!portalRoot) {
    portalRoot = document.createElement("div");
    portalRoot.setAttribute("id", elementName);
    document.body.appendChild(portalRoot);
  }

  const portalElement = document.createElement("div");

  useEffect(() => {
    portalRoot.appendChild(portalElement);
    return () => portalRoot.removeChild(portalElement);
  });

  return createPortal(children, portalElement);
}

Portal.propTypes = {
}

export default Portal
