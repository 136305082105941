/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { Component } from "react"
import PropTypes from "prop-types"
import { HeadingTag, PlusIcon, WYSIWYG } from "components"

class AccordionItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      targetNode: null
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  handleOpen() {
    const { targetNode } = this.state;

    let content = undefined;
    let closedContent = undefined;

    if (targetNode) {
      content = targetNode.querySelector(".Accordion-section-item_body.is-open");
      closedContent = targetNode.querySelector(".Accordion-section-item_body.is-closed");
    }

    if (content) {
      content.style.maxHeight = `${content.scrollHeight}px`;
    } else if (closedContent) {
      closedContent.style.maxHeight = "0px";
    }
  }

  handleClick(e) {
    let targetElement = null;

    if (e.target.classList.contains("Accordion-section-item_header")) {
      targetElement = e.target.parentNode.parentNode;
      this.setState({
        isOpen: !this.state.isOpen,
        targetNode: targetElement
      }, this.handleOpen);
    } else if (e.target.classList.contains("Accordion-section-item_content")) {
      this.setState({
        isOpen: !this.state.isOpen,
        targetNode: targetElement
      }, this.handleOpen);
    }
    return
  }

  render() {
    const { body, tag = "h5", title } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className={`Accordion-section-item ${isOpen ? "is-open" : ""}`}
        sx={{
          display: "flex",
          borderBottom: "3px solid",
          borderColor: "primary",
          mb: "spacing2",
          px: "0",
          pt: "spacing2",
      }}>
        <div
          role="button"
          tabIndex="0"
          aria-label={title ? `Accordion button for ${title}` : "Accordion button"}
          className="Accordion-section-item_content"
          onClick={this.handleClick}
          onKeyDown={this.handleKeyPress}
          sx={{
            background: "transparent",
            px: "0",
            py: "0",
            width: "100%",
            "&:focus": {
              outline: "none",
            }
          }}
        >
          <div className="Accordion-section-item_header"
            sx={{
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}>
            { title &&
              <HeadingTag
                text={title} tag={tag} overrideStyles={{
                  margin: "0",
                  pb: "spacing1",
                  pointerEvents: "none",
                  textAlign: "left",
              }} />
            }
            <span className="Accordion-section-item_toggle-icon"
              sx={{
                cursor: "pointer",
                flex: ["0 0 15px", null, "0 0 20px"],
                p: "0 2rem",
                pr: "1rem",
                pointerEvents: "none",

                "svg": {
                  height: "15px",
                  width: "15px"
                }
            }}>
              <PlusIcon isOpen={isOpen} />
            </span>
          </div>
          <div className={`Accordion-section-item_body ${isOpen ? "is-open" : "is-closed"}`}
            sx={{
              cursor: "auto",
              maxHeight: "0",
              overflow: "hidden",
              textAlign: "left",
              transition: "max-height 0.35s ease-out",
          }}>
            <WYSIWYG content={body} overrideStyles={{
              maxWidth: "850px",
              margin: "0",
              pb: "spacing3",
              pt: "spacing1",

              "a u": {
                textDecoration: "none",
              }
            }}/>
          </div>
        </div>
      </div>
    );
  }
}

AccordionItem.propTypes = {
  data: PropTypes.object
}

export default AccordionItem
