/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { schemaOrgWebPage, schemaEvent, schemaOrganization } from "./Schema.js"

function SEO({ lang, metaTags, translations, title }) {
  const hreflangData = translations.map(translation => ({
    rel: "alternate",
    hreflang: translation.langcode.value,
    href: translation.path_alias,
  }))

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={hreflangData}
      meta={metaTags}
    >
      {
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      }

      {
        <script type="application/ld+json">
          {JSON.stringify(schemaEvent)}
        </script>
      }

      {
        <script type="application/ld+json">
          {JSON.stringify(schemaOrganization)}
        </script>
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  metaTags: [],
  translations: [],
  title: "",
}

SEO.propTypes = {
  lang: PropTypes.string,
  metaTags: PropTypes.arrayOf(PropTypes.object),
  translations: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
}

export default SEO
