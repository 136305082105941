import React, { Component } from "react"
import Cookies from "js-cookie"
import { getQueryParam, utmsHelper } from "helpers"

class UTMS extends Component {
  constructor(props) {
    super(props)

    this.state = {
      utm_source: "",
      utm_campaign: "",
      utm_medium: "",
      utm_term: "",
      utm_content: "",
      utm_id: "",
    }
  }

  componentDidMount() {
    this.getUTMs()
  }

  getUTMs = () => {
    if (window.location.search !== "") {
      const utm_campaign = getQueryParam('utm_campaign', window.location.search);
      const utm_source = getQueryParam('utm_source', window.location.search);
      const utm_medium = getQueryParam('utm_medium', window.location.search);
      const utm_term = getQueryParam('utm_term', window.location.search);
      const utm_content = getQueryParam('utm_content', window.location.search);
      const utm_id = getQueryParam('utm_id', window.location.search);

      this.setState(
        {
          utm_source: utm_source ? utm_source : '',
          utm_campaign: utm_campaign ? utm_campaign : '',
          utm_medium: utm_medium ? utm_medium : '',
          utm_term: utm_term ? utm_term : '',
          utm_content: utm_content ? utm_content : '',
          utm_id: utm_id ? utm_id : ''
        },
        this.setCookie
      )
    }

    return
  }

  setCookie = () => {
    const {
      utm_medium,
      utm_source,
      utm_campaign,
      utm_term,
      utm_content,
      utm_id
    } = this.state

    if (utm_medium && utm_medium !== "") {
      Cookies.set(utmsHelper.COOKIE_NAME_UTM_MEDIUM, utm_medium)
    }
    if (utm_source && utm_source !== "") {
      Cookies.set(utmsHelper.COOKIE_NAME_UTM_SOURCE, utm_source)
    }
    if (utm_campaign && utm_campaign !== "") {
      Cookies.set(utmsHelper.COOKIE_NAME_UTM_CAMPAIGN, utm_campaign)
    }
    if (utm_term && utm_term !== "") {
      Cookies.set(utmsHelper.COOKIE_NAME_UTM_TERM, utm_term)
    }
    if (utm_content && utm_content !== "") {
      Cookies.set(utmsHelper.COOKIE_NAME_UTM_CONTENT, utm_content)
    }
    if (utm_id && utm_id !== "") {
      Cookies.set(utmsHelper.COOKIE_NAME_UTM_ID, utm_id)
    }
  }

  render() {
    return <div />
  }
}

UTMS.propTypes = {}

export default UTMS
