/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { ErrorMessage } from '@hookform/error-message';
import { jsx } from "theme-ui"
import {
  FieldWrapperStyles,
  InputStyles,
  LabelStyles,
} from "./FormElementStyles"
import Tooltip from "./Tooltip"

const Input = ({
  id,
  name,
  type,
  label,
  placeholder,
  value,
  required,
  fullWidth,
  overrideStyles,
}) => {
  const { register, errors } = useFormContext()

  const getPattern = (type) => {
    if (type === "email") {
      return (
        {
          // REG EX prevents extra `@` symbol, requires `.` to be placed after `@` e.g. @[gmail].[com]
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: 'Please check the formatting of your email address'
        }
      )
    }

    if (type === "tel") {
      return (
        {
          // REG EX validates for the following formats:
          // 1234567890
          // +12345678900
          // +123456789000
          // 1-234-567-8900
          // +1-234-567-8900
          // +12-345-678-9000
          value: /((^(1-)|(\+\d{1,3}-)?)\d{3}-\d{3}-\d{4}$|([0-9]{10,12})|(^(\+)?[0-9]{10,12}))/,
          message: 'Please check the formatting of your phone number'
        }
      )
    }

    return null
  }

  const errorHighlight = Object.keys(errors).length === 0 ? null : { borderColor: "inputError", outline: "2px solid", outlineColor: "inputError", }

  if (type.toLowerCase() === "checkbox") {
    return (
      <div
        sx={{
          display: "flex",
          width: "50%",
          position: "relative",
          ...overrideStyles,
        }}
      >
        {label && (
          <label
            sx={{
              ...LabelStyles,
              width: "100%",
            }}
            htmlFor={id || name}
          >
            <input
              type={type}
              id={id || name}
              name={name}
              maxLength='255'
              placeholder={placeholder}
              defaultValue={value}
              ref={register({
                required: {
                  value: required,
                  message: `This field is required.`,
                },
              })}
              sx={{
                ...InputStyles,
                width: "auto",
                marginRight: "spacing1",
                height: "auto",
              }}
            />
            {label}
          </label>
        )}
        {errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <Tooltip color='danger'>{message}</Tooltip>}
          />
        )}
      </div>
    )
  }

  return (
    <div
      sx={{
        width: fullWidth ? "100%" : "50%",
        ...FieldWrapperStyles,
        ...overrideStyles,
      }}
    >
      {label && (
        <label
          sx={{
            ...LabelStyles,
            display: "inline-block",
          }}
          htmlFor={id || name}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        id={id || name}
        name={name}
        maxLength='255'
        placeholder={placeholder}
        defaultValue={value}
        ref={register({
         pattern: getPattern(type),
          required: {
            value: required,
            message: `This field is required.`,
          },
        })}
        sx={{
          ...InputStyles,
          ...errorHighlight,
        }}
      />


        {errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <Tooltip color='danger'>{message}</Tooltip>}
          />
        )}
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  overrideStyles: PropTypes.shape({}),
}
Input.defaultProps = {
  type: "text",
  placeholder: "",
  required: false,
  value: "",
  id: null,
  fullWidth: false,
  overrideStyles: null,
}

export default Input
