/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import {
  Tooltip,
  CustomLink,
} from "components"

const SystemStatus = ({ tooltip, cta, subtitle }) => {
  return (
    <section
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        minWidth: ["100%", null, "300px"],
        margin: ["auto", null, "1.5rem"],
        pt: ["spacing5", null, "0"],
        float: ["right", "none", "none"],
        pb: "2.625rem",
        pl: [0, 0, 0, "spacing4"],
        "& + .BasicContent": {
          maxWidth: "770px",
        },
      }}
    >
      <div>
        <Tooltip tooltip={tooltip}>
          <div>
            <h6 sx={{ textTransform: "initial" }}>
              {subtitle?.label}
              <strong> {subtitle?.percent}</strong>
            </h6>
            {cta && (
              <CustomLink
                href={cta.link}
                overrideStyles={{
                  position: "relative",
                  float: ["none", "none", "left"],
                  textDecoration: "underline",
                  borderBottom: "none",
                }}
              >
                {cta.label}
              </CustomLink>
            )}
          </div>

        </Tooltip>
      </div>
    </section>
  )
}

SystemStatus.propTypes = {
  tooltip: PropTypes.string,
  cta: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  subtitle: PropTypes.shape({
    label: PropTypes.string,
    percent: PropTypes.string,
  }),
  status: PropTypes.string,
}

export default SystemStatus
