/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

const Tooltip = ({ children }) => {
  return (
    <span
      sx={{
        display: "block",
        left: "0",
        position: "relative",
        bottom: "auto",
        padding: "0",
        mt: "spacing1",
        background: "transparent",
        color: "inputError",

        "&:before": {
          display: "none",
        },
      }}
    >
      {children}
    </span>
  )
}

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default Tooltip
