/** @jsx jsx */
import React, { Component } from "react"
import { jsx } from "theme-ui"
import Cookies from "js-cookie"
import axios from "axios";
import Helmet from "react-helmet"
import { dlog, oktaEnvCheck } from "helpers"
import { CustomLink, Loading } from "components"

class RegistrationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successfulSubmit: false,
      formIsLoaded: false,
      error: '',
      userCase: true,
      showLegal: false,
      isRegistered: false,
      loginUrl: oktaEnvCheck("GATSBY_WATCH_NOW_URL"),
      onFormSuccessHappened: false,
      formSubmissionInProgress: false,
      showForm: true,
      myOktaSessionResponse: null,
      tempDataStorage: '',
      recaptchaLoaded: false,
      recaptchComplete: false,
    };

    this.eventIsLive = oktaEnvCheck("GATSBY_EVENT_IS_LIVE") === '1'
    this.eventFallbackBypass = oktaEnvCheck("GATSBY_OKTA_SSO_FALLBACK_BYPASS") === '1'

    this.myOktaSFDC = oktaEnvCheck("GATSBY_MYOKTA_SFDC")

    this.watchNowURL = oktaEnvCheck("GATSBY_WATCH_NOW_URL")
    this.watchNowURLMYOkta = oktaEnvCheck("GATSBY_WATCH_NOW_URL_MYOKTA")
    this.watchnowURLPartner = oktaEnvCheck("GATSBY_WATCH_NOW_URL_PARTNER")

    this.utmSource = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_SOURCE")
    this.utmContent = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_CONTENT")
    this.utmCampaign = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_CAMPAIGN")
    this.utmMedium = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_MEDIUM")
    this.utmTerm = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_TERM")
    this.utmId = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_TERM")

    // Form error messages.
    this.messages = {};

    // Cookie to help stop endless loops.
    this.registeredCookieName = 'Forum21EuropeRegistered';
    this.registeredCookieStopEnabled = oktaEnvCheck("GATSBY_ENABLE_COOKIE_STOP") === "1";
    this.loginUrlCookieName = 'Forum21EuropeLoginUrl';

    const { formId } = this.props;
    this.formElement = `mktoForm_${formId}`;
    this.legalCopy = <span><p>By submitting, I agree to the processing and international transfer of my personal data by Okta as described in the <a href="https://www.okta.com/privacy-policy/" target="_blank">Privacy Policy</a>.</p><p>By attending a sponsored session or visiting a <CustomLink href="/sponsors">sponsors</CustomLink> exhibition page, I agree to share my contact details with that sponsor for marketing outreach about their services.</p><p>For more information, please contact the specific sponsor directly for details about their privacy practices. Sponsor exhibition pages and sessions will be clearly marked on the virtual events platform.</p></span>
  }

  componentDidMount() {
    this.checkMarketoScriptLoad();
    this.checkIsRegistered();

    // Show the modal if they already didn't register already and they just landed on this page without referrer.
    if (
      this.state.isRegistered === false &&
      document.referrer.length < 1 &&
      document.querySelector('header .Register-button') !== null &&
      (this.state.recaptchaLoaded === false || typeof grecaptcha === 'undefined')
    ) {
      document.querySelector('header .Register-button').click();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.messages = {
      userExists:
      this.eventIsLive ?
        `<h2 style="margin-bottom: 15px;">Thank you for registering.</h2><h4>You may now join us at Forum21 Europe <a href='${this.state.loginUrl}'>here</a>.</h4>` :
        `<h2 style="margin-bottom: 15px;">Thank you for registering.</h2><h4 style="margin-bottom: 15px;">You will receive a confirmation email shortly.</h4> <p>Use the same email when entering the platform on the day of the event.</p> <p>See you at Forum21 Europe on 23 June.</p>`,
      withSFDC:
        this.eventIsLive ?
          `<h2 style="margin-bottom: 15px;">Thank you for registering.</h2><h4 style="margin-bottom: 15px;">You may now join us at Forum21 Europe <a href='${this.state.loginUrl}'>here</a>.</h4>` :
          `<h2 style="margin-bottom: 15px;">Thank you for registering.</h2><h4 style="margin-bottom: 15px;">You will receive a confirmation email shortly.</h4> <p>Use the same email when entering the platform on the day of the event.</p> <p>See you at Forum21 Europe on 23 June.</p>`,
      noAccount:
        this.eventIsLive ?
          `<h2 style="margin-bottom: 15px;">Thank you for registering for Forum21 Europe!</h2><h4>An Okta account has been created for you to be able to log into the Forum21 Europe virtual event platform.</h4><p>You have been sent an email to verify your new account. Please complete that step and then you can join us at Forum21 Europe <a href='${this.state.loginUrl}'>here</a>.</p>` :
          `<h2 style="margin-bottom: 15px;">You’re almost done. Check your inbox for two important emails.</h2><p>An Okta account has been created for you to be able to log into the platform on the day of the event.  You have been sent an email to verify your new account.  Please make sure to complete that step and use that same email when entering the platform on the day of the event.</p><p>We’ve also sent you an event confirmation email.</p><p>See you at Forum21 Europe on 23 June.</p>`,
      withoutSFDC:
        this.eventIsLive ?
          `<h2 style="margin-bottom: 15px;">Thank you for registering for Forum21 Europe!</h2><h4>An Okta account has been created for you to be able to log into the Forum21 Europe virtual event platform.</h4><p>You have been sent an email to verify your new account. Please complete that step and then you can join us at Forum21 Europe <a href='${this.state.loginUrl}'>here</a>.</p>` :
          `<h2 style="margin-bottom: 15px;">You’re almost done. Check your inbox for two important emails.</h2><p>An Okta account has been created for you to be able to log into the platform on the day of the event.  You have been sent an email to verify your new account.  Please make sure to complete that step and use that same email when entering the platform on the day of the event.</p><p>We’ve also sent you an event confirmation email.</p><p>See you at Forum21 Europe on 23 June.</p>`,
      isRegistered:
        this.eventIsLive ?
          `<div style="text-align: center; margin-bottom: 15px;"><h2>Registration already completed</h2><h4 style="margin-bottom: 15px;">You may now join us at Forum21 Europe <a href='${this.state.loginUrl}'>here</a>.</h4></div>` :
          `<h2 style="margin-bottom: 15px;">Thank you for registering.</h2><h4 style="margin-bottom: 15px;">You will receive a confirmation email shortly.</h4> <p>Use the same email when entering the platform on the day of the event.</p> <p>See you at Forum21 Europe on 23 June.</p>`,
    }
  }

  handleRecaptcha(form) {
    const recaptchaKey = oktaEnvCheck("GATSBY_GOOGLE_RECAPTCHA_KEY");
    // Make sure the grecaptcha exists
    if (typeof grecaptcha !== 'undefined' && recaptchaKey) {
    // Make sure it is ready
      grecaptcha.ready(function() {
        try {
          // We only want to load the recaptcha once
          if (this.state.recaptchaLoaded === false) {
            // Create our recaptcha
            grecaptcha.render('grecaptcha', {
              sitekey: recaptchaKey,
              callback: (response) => {
                this.setState({recaptchComplete: true});
                form.submittable(true);
                form
                  .getFormElem()
                  .find(".mktoButton")
                  .css("cursor", "pointer")
                  .prop("disabled", false)
              }
            });
            this.setState({recaptchaLoaded: true});
            form.submittable(false);
            form
              .getFormElem()
              .find(".mktoButton")
              .css("cursor", "not-allowed")
              .prop("disabled", true)
          }
        } catch (e) {
          console.log(e);
        }
      }.bind(this, form));
    } else {
      // Fallback to enabled form if the recaptcha didn't load
      this.setState({recaptchComplete: true});
      form.submittable(true);
    }
  }

  checkIsRegistered() {
    if (
      this.registeredCookieStopEnabled &&
      Cookies.get(this.registeredCookieName) === 'true'
    ) {
      document.querySelector('.HeadingTag').remove();
      this.setState({isRegistered: true});
    }
  }

  checkMarketoScriptLoad() {
    if (!this.state.formIsLoaded) {
      this.handleMarketoLoading();
      this.changeEmailOptInCanada();
    }
    else {
    }
  }

  changeEmailOptInCanada() {
    const self = this;

    if (typeof window.MktoForms2 !== 'undefined') {

      window.MktoForms2.whenReady(function(form) {
        const placeholder = document.querySelector(`#${self.formElement} .mktoPlaceholderEmail_Opt_In__c`);
        if (placeholder) {
          const placeholderParent = placeholder.parentElement;
          placeholderParent.classList.add('Email-op-in');
        }

        const agreeLabel = document.querySelector(
          `label[for='termsandConditions']`
        )
        if (agreeLabel) {
          const agreeLabelParent = agreeLabel.parentElement
          agreeLabelParent.classList.add("Email-op-in")
        }
      });

    }
  }

  createUser(form) {
    dlog('Begin createUser()');

    form
      .getFormElem()
      .find(".mktoButton")
      .html("Please Wait")
      .prop("disabled", true)

    // Clear out any error messages
    this.setState({ error: '' })

    const vals = form.getValues();
    const myOktaSession = this.state.myOktaSessionResponse;
    const registrationAPI = oktaEnvCheck("GATSBY_REGISTER_IS_STAGING") ? oktaEnvCheck("GATSBY_REGISTER_API_STAGE") : oktaEnvCheck("GATSBY_REGISTER_API");
    
    const options = {
      method: "POST",
      url: registrationAPI,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      },
      data: {
        "profile": vals,
        "session": myOktaSession,
        "cid": "2021-06-CNF-VIRT-Okta-OktaForum21-EMEA-General-CNF",
      },
    };

    axios(options).then((response) => {
      dlog('createOktaUser() axios response', response);

      // if success or fallback bypass, set state to show thank you
      if (
        response.data.status === "user exists" ||
        response.data.status === "new user created" ||
        response.data.status === "MeetingPlay user created" ||
        this.eventFallbackBypass
      ) {

        if (response.data.status === "user exists") {
          this.setState({ userCase: "userExists" });
        }

        this.setState({ successfulSubmit: true });

        if (!form.submittable()) {
          // Set it to be non submittable
          form.submittable(true);
          form.submit();
        }

        setTimeout(() => {
          this.forceOnFormSuccess(form);
        }, 10000)

        return true;
      } else {
        // Remove the header and scrollto the top.
        if (typeof window !== "undefined") {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        document.querySelector('.RegisterHeading').remove();

        if (!form.submittable()) {
          // Set it to be submittable
          form.submittable(true);
        }

        return false;
      }
    }).catch((error) => {
      dlog('createOktaUser() axios catch()');
      dlog(error.message);
      let validationError = '';

      if (error?.response?.data?.errors?.length) {
        for (let i = 0; i < error?.response?.data?.errors?.length; i++) {
          validationError +=  error?.response?.data?.errors[i] + ". ";
        }
        this.setState({ error: validationError + "Please try again." });
      } else {
        this.setState({ error: 'Something went wrong. Please try again.' });
      }

      form
        .getFormElem()
        .find(".mktoButton")
        .html("Submit")
        .prop("disabled", false)

      form.submittable(true);

      return false;
    });
  }

  createMeetingPlayUser(form) {
    var vals = form.vals();
    const mpAPI = oktaEnvCheck("GATSBY_API_MEETINGPLAY");
    const mpVals = {
      firstName: vals.FirstName,
      lastName: vals.LastName,
      email: vals.Email,
      language: vals.preferredLanguage,
      company: vals.Company,
      country: vals.Country,
      jobTitle: vals.Title,
      regType: vals.multiUseIntake1,
      state: vals.State,
      pinCode: vals.tempDataStorage3,
    };

    if (!vals.tempDataStorage || vals.tempDataStorage.length < 1) {
      // No tempDataStorage, use hardcoded value.
      mpVals.sfdcaccountid = this.myOktaSFDC + vals.Email;
    }
    else {
      // Use submitted tempDataStorage.
      mpVals.sfdcaccountid = vals.tempDataStorage;
    }

    const options = {
      method: "POST",
      url: mpAPI,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      },
      data: mpVals
    };

    axios(options).then((response) => {
      if (
        this.registeredCookieStopEnabled &&
        Cookies
      ) {
        Cookies.set(this.registeredCookieName, 'true');
      }
      // Error handling and logging handled in backend.
      return true;
    }).catch((error) => {
      return false;
    });
  }

  prefillLoginUrl(form) {
    // Login URL to be sent via email and on Thank You page day-of event.
    // Default to MyOkta.
    let loginUrl = watchNowURLMYOkta;

    dlog('prefillLoginUrl() » loginUrl (initial set)', loginUrl)

    if (!this.visitorCameFromSSO()) {
      form.vals({ 'tempDataStorage2': loginUrl });
      this.setState({ loginUrl: loginUrl });
      return true
    }

    // Set User's data
    const options = {
      method: "GET",
      url: '/oktaapi/myokta/session/'
    };

    axios(options).then(response => {
      dlog('prefillLoginUrl() » axios » then() » response', response)
      const loggedInAsPartner = oktaEnvCheck("GATSBY_ORG_URL_PARTNER")
      const loggedInViaMyOkta = oktaEnvCheck("GATSBY_ORG_URL_MYOKTA")
      const loggedIntoAnyOtherOrg = watchNowURL
      const newUser = watchNowURLMYOkta
      // Logged in as partner.
      if (response?.data?.attributes?.org_url === loggedInAsPartner) {
        loginUrl = loggedInAsPartner;
      }
      // Logged in via MyOkta.
      else if (response?.data?.attributes?.org_url === loggedInViaMyOkta) {
        loginUrl = loggedInViaMyOkta;
      }
      // Logged in to any other org.
      else if (response?.data?.attributes?.org_url?.length > 0) {
        loginUrl = loggedIntoAnyOtherOrg;
      }
      // No org_url, new user.
      else {
        loginUrl = newUser;
      }

      dlog('prefillLoginUrl() » loginUrl (after if/else block)', loginUrl)

      // If tempDataStorage exists on myokta session assing it
      if (response?.data?.tempDataStorage?.length  > 0) {
        this.setState({ tempDataStorage: response.data.tempDataStorage });
      }

      // No org_url? Likely making a new MyOkta account.
      form.vals({ 'tempDataStorage2': loginUrl });
      this.setState({ loginUrl: loginUrl });

      return response
    })
      .catch(error => {
        form.vals({ 'tempDataStorage2': loginUrl });
        this.setState({ loginUrl: loginUrl });
        return false
      });
  }

  prefillMarketoForm(form) {
    // Set User's data
    const options = {
      method: "GET",
      url: '/oktaapi/myokta/session/'
    };

    axios(options).then(response => {
      if (response.data && response.data.Email) {
        // Store the session to send to API
        this.setState({ myOktaSessionResponse: response.data.attributes });

        // Use MktoForms2 API to fill fields if available.
        if (typeof window.MktoForms2 === "object") {
          window.MktoForms2.whenReady(function(form) {
            form.vals(response.data)
            if (response.data.Email) {
              form
                .getFormElem()
                .find("#Email")
                .attr('disabled', true);
            }
          })
        }
        if (response.data.ContactId) {
          this.setState({ userCase: "withSFDC" })
          form.vals({ 'multiUseIntake2': 'withSFDC'})
        } else {
          this.setState({ userCase: "withoutSFDC" })
          form.vals({ 'multiUseIntake2': 'withoutSFDC' })
        }
      } else {
        this.setState({ userCase: "noAccount" })
        form.vals({ 'multiUseIntake2': 'noAccount' })
      }

      return response
    })
      .catch(error => {
        return false
      })
  }

  /**
   * Custom function to force thank-you message if
   *
   * @param form
   */
  forceOnFormSuccess(form) {
    dlog('Start forceOnFormSuccess()');

    if (this.state.formSubmissionInProgress === true) {
      dlog('this.state.formSubmissionInProgress', this.state.formSubmissionInProgress);
      this.onFormSuccess(form);
    }
  }

  sendDataLayer(form) {
    if (window) {
      window.dataLayer = window.dataLayer || [];
      const vals = form.vals();
      window.dataLayer.push({
        event: "FormSubmit",
        formMetaData: {
          formType: "mql",
          formCampaign: "",
          formCategory: "eventRegistration",
          formAction: "10_EventReg",
        },
        OktaFormMetaData: vals,
      });
    }
  }

  /**
   * Marketo hook when form submission is successful.
   *
   * @param form
   * @returns {boolean}
   */
   onFormSuccess(form) {
    dlog('Start onFormSuccess()');
    this.sendDataLayer(form);
    this.setState({formSubmissionInProgress: false});

    if (this.state.onFormSuccessHappened === false) {
      this.setState({ onFormSuccessHappened: true });

      // Scroll to top
      if (typeof window !== "undefined") {
        document.querySelector('.HeadingTag').remove();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }

      if (Cookies) {
        Cookies.set(this.loginUrlCookieName, this.state.loginUrl);
      }

      // On fallback site, always show simple thankyou message.
      if (
        this.eventIsLive &&
        this.eventFallbackBypass
      ) {
        this.setState({ userCase: "withSFDC" })
      }

      // Clear out any error messages
      this.setState({ error: '' })

      form
        .getFormElem()
        .parents(".Form-wrapper")
        .html("<div class='Form-thankyou'>" + this.messages[this.state.userCase] + "</div>")
    }

    return false;
  }

  onMtkoSubmit(form) {
    dlog('Submitting...');
    this.setState({formSubmissionInProgress: true});

    // Check if the form is submittable
    if (form.submittable()) {
      // Set it to be non submittable
      form.submittable(false);
    }
    var vals = form.vals();

    dlog('Marketo submission vals', vals);

    if (this.state.tempDataStorage) {
      form.vals({ "tempDataStorage": this.state.tempDataStorage });
    } else {
      form.vals({ "tempDataStorage": this.myOktaSFDC + vals.Email });
    }


    // If @okta.com (and not partner), force change the tempDataStorage2.
    if (
      vals?.Email?.slice(-9) === '@okta.com' &&
      this.state.loginUrl !== watchnowURLPartner
    ) {
      form.vals({ 'tempDataStorage2': watchNowURL });
      this.setState({loginUrl: watchNowURL});
      this.forceUpdate();
    }

    dlog('this.state.loginUrl', this.state.loginUrl);

    if (!this.state.successfulSubmit) {
      this.createUser(form);
    } else {
      form.submittable(true);
    }
  }

  onMarketoReady(form) {
    // Add classes for styling checkboxes
    form
      .getFormElem()
      .find("label[for='sponsorShareConsent']")
      .parent().addClass('row-flex')

    form
      .getFormElem()
      .find("select#Country")
      .change(function(){
        form
        .getFormElem()
        .find("label[for='emailOptInRequired']")
        .parent().addClass('row-flex')
      });

    // Find submit button, and prepend recaptcha above it.
    form
      .getFormElem()
      .find("button[type='submit']")
      .parent()
      .prepend('<div style="margin-bottom: 10px;"><div id="grecaptcha"></div></div>')

    this.handleRecaptcha(form);

    // Set utms
    if (Cookies) {
      const utmSourceCookie = Cookies.get(this.utmSource)
      const utmContentCookie = Cookies.get(this.utmContent)
      const utmCampaignCookie = Cookies.get(this.utmCampaign)
      const utmMediumCookie = Cookies.get(this.utmMedium)
      const utmTermCookie = Cookies.get(this.utmTerm)
      const utmIdCookie = Cookies.get(this.utmId)

      form.vals({
        'utm_source__c': utmSourceCookie,
        'utm_content__c': utmContentCookie,
        'utm_campaign__c': utmCampaignCookie,
        'utm_medium__c': utmMediumCookie,
        'utm_term__c': utmTermCookie,
        'UTM_ID__c': utmIdCookie,
      })

      // From www.okta.com
      const utmSession = Cookies.get("_okta_session_attribution")
      const utmOriginal = Cookies.get("_okta_original_attribution")

      if (utmSession) {
        const utmSessionValues = JSON.parse(unescape(utmSession));
        const utmSessionPage = utmSessionValues.utm_page ? utmSessionValues.utm_page : '';
        const utmSessionDate = utmSessionValues.utm_date ? utmSessionValues.utm_date : '';
        const utmSessionCampaign = utmSessionValues.utm_campaign ? utmSessionValues.utm_campaign : '';
        const utmSessionMedium = utmSessionValues.utm_medium ? utmSessionValues.utm_medium : '';
        const utmSessionSource = utmSessionValues.utm_source ? utmSessionValues.utm_source : '';
        const utmSessionContent = utmSessionValues.utm_content ? utmSessionValues.utm_content : '';
        const utmSessionTerm = utmSessionValues.utm_term ? utmSessionValues.utm_term : '';
        form.vals({
          'session_utm_campaign__c': utmSessionCampaign,
          'session_utm_content__c': utmSessionContent,
          'session_utm_date__c': utmSessionDate,
          'session_utm_medium__c': utmSessionMedium,
          'session_utm_page__c': utmSessionPage,
          'session_utm_source__c': utmSessionSource,
          'session_utm_term__c': utmSessionTerm,
        })
      }

      if (utmOriginal) {
        const utmOriginalValues = JSON.parse(unescape(utmOriginal));
        const utmOriginalPage = utmOriginalValues.utm_page ? utmOriginalValues.utm_page : '';
        const utmOriginalDate = utmOriginalValues.utm_date ? utmOriginalValues.utm_date : '';
        const utmOriginalCampaign = utmOriginalValues.utm_campaign ? utmOriginalValues.utm_campaign : '';
        const utmOriginalMedium = utmOriginalValues.utm_medium ? utmOriginalValues.utm_medium : '';
        const utmOriginalSource = utmOriginalValues.utm_source ? utmOriginalValues.utm_source : '';
        const utmOriginalContent = utmOriginalValues.utm_content ? utmOriginalValues.utm_content : '';
        const utmOriginalTerm = utmOriginalValues.utm_term ? utmOriginalValues.utm_term : '';

        form.vals({
          'original_utm_campaign__c': utmOriginalCampaign,
          'original_utm_content__c': utmOriginalContent,
          'original_utm_date__c': utmOriginalDate,
          'original_utm_medium__c': utmOriginalMedium,
          'original_utm_page__c': utmOriginalPage,
          'original_utm_source__c': utmOriginalSource,
          'original_utm_term__c': utmOriginalTerm,
        })
      }

    }
    this.setState({
      showLegal: true,
    })

    if (this.visitorCameFromSSO()) {
      this.prefillMarketoForm(form);
    } else {
      this.setState({ userCase: "noAccount" })
      form.vals({ 'multiUseIntake2': 'noAccount'})
    }
    this.prefillLoginUrl(form);
    form.vals({'tempDataStorage3': this.makeid()});
    form.vals({'cid': '2021-06-CNF-VIRT-Okta-OktaForum21-EMEA-General-CNF'});
    form.onSubmit(this.onMtkoSubmit.bind(this, form));
    form.onSuccess(this.onFormSuccess.bind(this, form));
  }

  visitorCameFromSSO() {
    const ssoReferrer = oktaEnvCheck("GATSBY_OKTA_SSO_REFERRER")
    return (
      // Document is defined.
      typeof document !== 'undefined' &&
      // Not coming from www.okta.com.
      document.referrer.indexOf('www.okta.com') === -1 &&
      (
        // Most referrers from Okta login.
        document.referrer.indexOf('user/sso/corpwebsite') > 1 ||
        // Edge case for some Okta login e.g. ".okta.com".
        document.referrer.indexOf('.' + ssoReferrer) > 1 ||
        // Edge case for some Okta login. e.g. "//clouditude.com".
        document.referrer.indexOf('//' + ssoReferrer) > 1
      )
    )
  }

  handleMarketoLoading() {
    if (typeof window.MktoForms2 !== 'undefined') {
      // eslint-disable-next-line no-undef
      MktoForms2.loadForm("//pages.okta.com/", "855-QAH-699", this.props.formId,  function (form) {
      });
      this.setState({
        formIsLoaded: true
      })
      window.MktoForms2.whenReady(this.onMarketoReady.bind(this));
    } else {
      window.setTimeout(() => {
        this.checkMarketoScriptLoad();
      }, 500)
    }
  }

  makeid() {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!()';
    var charactersLength = characters.length;
    for ( var i = 0; i < 12; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (
      result.indexOf('!') === -1 &&
      result.indexOf(')') === -1 &&
      result.indexOf('(') === -1
    ) {
      return this.makeid();
    }
    return result;
  }

  render() {
    const { formId } = this.props;
    return (
      <div sx={{ variant: "forms.marketo", }}>
        <Helmet>
          <script src="//pages.okta.com/js/forms2/js/forms2.min.js" async />
        </Helmet>
        {
          this.state.isRegistered ?
            <div dangerouslySetInnerHTML={{__html: this.messages.isRegistered}} />
            :
            <div className="Form-wrapper">
              {!this.state.formIsLoaded && <Loading />}
              {this.state.showForm &&
                <form ref={this.formElement} id={`mktoForm_${formId}`} />
              }
              <div className="Form-error">
                {this.state.error && (
                  <div sx={{ color: "red", fontWeight: "600" }} dangerouslySetInnerHTML={{ __html: this.state.error }}></div>
                )}
              </div>
              {this.state.showLegal && (
                <div className="Form-legal" sx={{ mt: "spacing3" }}>
                  {this.legalCopy }
                </div>
              )}
            </div>
        }
      </div>
    )
  }
}

export default RegistrationForm
