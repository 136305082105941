/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { Button, CustomLink } from "components"

const CTA = ({ href = "", size = "", style = "", text = "" }) => {
  if (style === "plain") {
    return <CustomLink href={href}>{text}</CustomLink>
  }

  return <Button style={style} size={size} href={href}>{text}</Button>
}

CTA.propTypes = {
}

export default CTA
