import Cookies from "js-cookie";
import { oktaEnvCheck } from "helpers"

const COOKIE_NAME_UTM_CAMPAIGN = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_CAMPAIGN")
const COOKIE_NAME_UTM_SOURCE = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_SOURCE")
const COOKIE_NAME_UTM_MEDIUM = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_MEDIUM")
const COOKIE_NAME_UTM_TERM = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_TERM")
const COOKIE_NAME_UTM_CONTENT = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_CONTENT")
const COOKIE_NAME_UTM_ID = oktaEnvCheck("GATSBY_COOKIE_NAME_UTM_ID")
const UTM_SITE_NAME = oktaEnvCheck("GATSBY_UTM_SITE_NAME")

class utmsHelper {

  static get COOKIE_NAME_UTM_CAMPAIGN() {
    return COOKIE_NAME_UTM_CAMPAIGN
  }

  static get COOKIE_NAME_UTM_SOURCE() {
    return COOKIE_NAME_UTM_SOURCE
  }

  static get COOKIE_NAME_UTM_MEDIUM() {
    return COOKIE_NAME_UTM_MEDIUM
  }

  static get COOKIE_NAME_UTM_TERM() {
    return COOKIE_NAME_UTM_TERM
  }

  static get COOKIE_NAME_UTM_CONTENT() {
    return COOKIE_NAME_UTM_CONTENT
  }

  static get COOKIE_NAME_UTM_ID() {
    return COOKIE_NAME_UTM_ID
  }

  static buildUrlParamsFromCookies = ({encoded, questionMark, equals, ampersand}) => {
    encoded = encoded || false
    questionMark = questionMark || '?'
    equals = equals || '='
    ampersand = ampersand || '&'
    let debug = oktaEnvCheck("GATSBY_DEBUG") === '1'

    // Default values.
    if (Cookies) {
      if (debug) {
        console.log('utmsHelper.js::buildUrlParamsFromCookies Cookies exists')
      }

      const utmParams = [
        this.COOKIE_NAME_UTM_CAMPAIGN,
        this.COOKIE_NAME_UTM_SOURCE,
        this.COOKIE_NAME_UTM_MEDIUM,
        this.COOKIE_NAME_UTM_TERM,
        this.COOKIE_NAME_UTM_CONTENT,
        this.COOKIE_NAME_UTM_ID,
      ]
      let utms = ""
      utmParams.forEach(val => {
        if (Cookies.get(val)) {
          utms += val.replace(UTM_SITE_NAME, '') + equals + Cookies.get(val) + ampersand
        }
      })

      if (utms.length > 0) {
        // Remove final ampersand.
        utms = utms.slice(0, -1)
        utms = questionMark + utms

        if (debug) {
          console.log('utmsHelper.js::buildUrlParamsFromCookies utms = ', utms)
        }

        if (encoded && encoded === true) {
          if (debug) {
            console.log('utmsHelper.js::buildUrlParamsFromCookies Encoding UTMs')
          }

          return encodeURIComponent(utms)
        }
        else {
          if (debug) {
            console.log('utmsHelper.js::buildUrlParamsFromCookies Not encoding UTMs')
          }

          return utms
        }
      }
    }

    if (debug) {
      console.log('utmsHelper.js::buildUrlParamsFromCookies Cookies does not exist')
    }

    return '';
  }

}

export default utmsHelper
