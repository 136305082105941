/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { Component } from "react"
import { Button, LogoIcon } from "components"
import Cookies from "js-cookie"
import { oktaEnvCheck, utmsHelper } from "helpers"

class RegistrationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registerLink: `${oktaEnvCheck("GATSBY_PATH_PREFIX")}/register`,
      ssoLink: oktaEnvCheck("GATSBY_REGISTER_MODAL_SSO_LINK"),
      utms: "",
    }
  }

  componentDidMount() {
    if (Cookies) {
      this.setState({'utms': utmsHelper.buildUrlParamsFromCookies({encoded: true, questionMark: '', ampersand: ';', suffix: ';'})})
    }
  }

  render() {
    const { ssoLink, registerLink, utms } = this.state;
    const { lang, customContent } = this.props;

    const choiceStyles = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: "1 1 50%",
      minWidth: "50%",
      padding: ["20px", null, null, "spacing3"],
      textAlign: ["center", null, null, "left"],
      color: "#000",

      "&:first-of-type": {
        textAlign: "center",
      }
    }

    const textStyles = {
      color: "#000",
      mb: "spacing2"
    }

    return (
      <div sx={{
        width: "100%",
        marginTop: ["spacing4", null, null, "auto"],
      }} className="Registration">
        <div sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mx: "auto",
          mb: "spacing5",
          "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
            justifyContent: "flex-start",
          },
        }}>
          <div sx={{
            flex: "0 1 300px",
            mx: "auto",
          }}>
            <LogoIcon overrideStyles={{
              m: "auto",
              maxWidth: ["200px", null, null, "300px"],
              padding: ["spacing1", null, null, 0],
            }} />
          </div>
        </div>

        <div sx={{
          display: "flex",
          flexDirection: ["column", null, null, "row"],
          justifyContent: "center",
          my: ["spacing2", null, null, "spacing4"],
          width: "100%",
        }} className="Registration-content-wrapper">


          <div sx={{ ...choiceStyles }}>
            <p sx={{ ...textStyles }}>I use Okta at my organization*</p>
            {/*if using CVENT href will need to be {ssoLink + utms}*/}
            <Button href={ssoLink} style="primary">Continue with Okta</Button>
          </div>

          <div sx={{
            minWidth: "0",
            width: ["100%", null, null, "auto"],
            maxWidth: ["100%", null, null, "1px"],
            mx: ["0", null, null, "10px"],
            borderRight: ["none", null, null, "1px solid rgba(0, 0, 0, 0.35)"],
            borderBottom: ["1px solid rgba(0, 0, 0, 0.35)", null, null, "none"],
          }} className="Registration-content-divider"></div>

          <div sx={{ ...choiceStyles }}>
            <p sx={{ ...textStyles }}>I don't use Okta at my organization</p>
            {/*
              Temp hiding and replacing with regular anchor
              because hovering a Gatsby link causes a state change/prefetch that refreshes the modal

              <Button href={registerLink} style="secondary">Click here to register</Button>
            */}
            <a
              className="Button Button-secondary-regular"
              sx={{
                variant: "buttons.secondary",
              }}
              href={registerLink}>Click here to register</a>
          </div>
        </div>

        <div sx={{
          mx: "auto",
          mb: "spacing4",
          textAlign: "center",
          px: "spacing4",
        }}>
            <p sx={{ ...textStyles, fontSize: "0.875rem", mt: "spacing4" }}>*For your convenience, you can choose to register for Forum21 Europe using information from your Okta account. If you do not wish to use this feature, then please register using the option for non-Okta customers.</p>
        </div>

        <small sx={{
          display: "block",
          textAlign: "center",
          margin: "auto",
        }}>Registration powered by Okta</small>
        {customContent}
      </div>
    )
  }
}

RegistrationModal.propTypes = {
}

export default RegistrationModal
