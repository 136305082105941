export default {
    links: [
        {
            "label": "Status",
            "description": null,
            "url": {
                "href": "https://status.okta.com/",
                "internal": false
            }
        },
        {
            "label": "Security",
            "description": null,
            "url": {
                "href": "/security",
                "internal": false
            }
        },
        {
            "label": "Compliance",
            "description": null,
            "url": {
                "href": "/compliance",
                "internal": false
            }
        },
        {
            "label": "Help Center",
            "description": null,
            "url": {
                "href": "https://support.okta.com/help/s/",
                "internal": false
            }
        }
    ]
}