/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Header, Footer, UTMS } from "components"

class Layout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      headerHeight: "77px",
    }

    this.headerWrapper = React.createRef()
  }

  componentDidMount() {
    this.getHeaderHeight()
    window.addEventListener("resize", this.throttledResize, false)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.throttledResize, false)
  }

  getHeaderHeight = () => {
    const header = document.querySelector("header")
    this.setState({
      headerHeight: header ? header.offsetHeight : this.state.headerHeight,
    })
  }

  throttledResize = () => {
    window.requestAnimationFrame(this.getHeaderHeight)
  }

  render() {
    const { children } = this.props
    const { headerHeight } = this.state

    return (
      <div sx={{ position: "relative" }}>
        <Header ref={this.headerWrapper} />
        <main sx={{ mt: headerHeight }}>{children}</main>
        <Footer />
        <UTMS />
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
