import { useEffect, useState } from "react";
import { globalHistory } from '@reach/router'
import { freezeWindow, unfreezeWindow } from "helpers"

const ModalProvider = () => {
  const [open, onOpenModal] = useState(false)
  const [close, onCloseModal] = useState(false)
  const [scrollState, setScrollState] = useState(null)

  // TBD: improve accessiblity of modal by limiting focus to clickable elements inside of modal when open
  // const accessibilityHelper = (e) => {
  //   console.log("clicking: ", e)
  //   const  focusableElements =
  //       'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  //   const modal = document.querySelector('#modal'); // select the modal by it's id

  //   const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
  //   const focusableContent = modal.querySelectorAll(focusableElements);
  //   const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

  //   let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

  //   if (!isTabPressed) {
  //      console.log("nonpe")
  //     return;
  //   }

  //   if (e.shiftKey) { // if shift key pressed for shift + tab combination
  //     console.log("first if")
  //     if (document.activeElement === firstFocusableElement) {
  //       lastFocusableElement.focus(); // add focus for the last focusable element
  //       e.preventDefault();
  //     }
  //   } else { // if tab key is pressed
  //     console.log("second: ", document.activeElement, lastFocusableElement, firstFocusableElement)
  //     if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
  //       firstFocusableElement.focus(); // add focus for the first focusable element
  //       e.preventDefault();
  //     }
  //   }

  //   firstFocusableElement.focus();
  // }

  const openModal = (e) => {
    e && e.preventDefault();
    onOpenModal(true)
    freezeWindow()
  };

  const closeModal = () => {
    onCloseModal(true);
    onOpenModal(false);
    unfreezeWindow()
  };

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        closeModal();
      }
    })

    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });

    return () => {
      window.removeEventListener('scroll', () => {
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
      });
    }
  })

  return { open, close, openModal, closeModal };
};

export default ModalProvider;
