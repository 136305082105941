/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
// import { Link } from "@reach/router";
import { internalURL } from "helpers"

const Button = ({ children, classes = "", clickFunction, disabled, href, size, style, external, overrideStyles }) => {
  const buttonStyle = style ? style : "primary"
  const buttonSize = size ? size : "regular"
  const buttonVariant = buttonSize === "large" ? [`buttons.${buttonStyle}`, null, null, `buttons.${buttonStyle}Large`] : `buttons.${buttonStyle}`

  if (!href) {
    return (
      <button
        className={`Button Button-${buttonStyle}-${buttonSize} ${classes}`}
        disabled={disabled}
        sx={{
          variant: buttonVariant,
          ...overrideStyles
        }}
        onClick={clickFunction}>{children}</button>
    )
  }

  if (external || href.includes("http")) {
    return (
      <a
        className={`Button Button-${buttonStyle}-${buttonSize} ${classes}`}
        sx={{
          variant: buttonVariant,
          ...overrideStyles
        }}
        href={href}>{children}</a>
    )
  }

  return (
    <Link
      className={`Button Button-${buttonStyle}-${buttonSize} ${classes}`}
      sx={{
        variant: buttonVariant,
        ...overrideStyles
      }}
      to={internalURL(href)}>{children}</Link>
  )
}

export default Button
