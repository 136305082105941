/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, CTA, HeadingTag, WYSIWYG } from "components"
import { getSectionVariant } from "helpers"

const Hero = ({ fromQuery, alt = "", bgColor = "primaryHero", body, children, image, subtitle, title }) => {
  const bodyStyles = {
    mb: "spacing4",
    maxWidth: "600px",
  }

  const ctaStyles = {
    width: "100%"
  }

  const imgStyles = {
    mb: "spacing3",
    maxWidth: "300px",
  }

  const subtitleStyles = {
    mb: "spacing4",
    maxWidth: "100%"
  }

  const titleStyles = {
    mb: "spacing1"
  }

  const bgColorVariant = getSectionVariant(bgColor)

  return (
    <div
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        minHeight: "600px",
        py: ["spacing6", null, null, "spacing6"],
        position: "relative",
        ...bgColorVariant,
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
          bg: "#FCE8C5",
        }
      }}
    >
      <Container>
        <div sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}>
          {image && <div sx={{ ...imgStyles }}>
            {fromQuery && <GatsbyImage image={image} alt={alt} />}
            {!fromQuery && <img src={image} alt={alt} />}
          </div>}
          {title && <div sx={{ ...titleStyles }}><HeadingTag text={title?.text} tag={title?.tag} display={title?.display} /></div>}
          {subtitle && <div sx={{ ...subtitleStyles }}><HeadingTag text={subtitle?.text} tag={subtitle?.tag} display={subtitle?.display} /></div>}
          {body && <div sx={{ ...bodyStyles, }}><WYSIWYG content={body} /></div>}
          {children && <div sx={{ ...ctaStyles }}>{children}</div>}
        </div>
      </Container>
    </div>
  )
}

Hero.propTypes = {
}

export default Hero
